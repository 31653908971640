import * as React from "react";
import { Button, Container, Dimmer, Dropdown, Grid, Loader, Message, Segment } from "semantic-ui-react";
import { StringParam, useQueryParam, useQueryParams } from "use-query-params";
import { useApplySelectorListAction, useIgnoreSelectorListActions, useInvalidateSelectorListCaches, useUnignoreSelectorListAction } from "../../api";
import { SchoolDropdown } from "../../components/SchoolDropdown";
import SelectorListDetail from "./SelectorListDetail";
import { ActionTypeDropdown, IgnoreDropdown, LivenessDropdown } from "./SelectorListDropdowns";
import SelectorListMenu from "./SelectorListMenu";
import SelectorListNoSelection from "./SelectorListNoSelection";
import SelectorListSidebar from "./SelectorListSidebar";
import Title from "../../components/Title";
import { ApplyAction, IgnoreAction, UnignoreAction, useSelectorListActions } from "../../hooks/useSelectorListActions";
import { SelectorActionType } from "../../model/selectorList";
import { schoolToTeamCode } from "../../model/team";
import { ApplicationLiveness, IgnoredOption, SchoolCode, SelectorListApplicant } from "../../model/types";
import { IgnoredOptionEnum } from "../../model/enums";
import { BatchInProgress } from "@qmspringboard/app/src/pages/SelectorListSummaryPage/BatchInProgress";
import { Link } from "react-router-dom";
import { formatQueryString } from "@qmspringboard/app/src/utils/queryParams";

type Props = {
  busyMessage?: string;
};

const SelectorListPageBody = ({
  data,
  selected,
  fetching,
  onClick,
  schoolCode,
  onApplyAction,
  onIgnoreAction,
  onUnignoreAction,
}: {
  data: SelectorListApplicant[];
  selected?: string | null;
  fetching: boolean;
  onClick: (ucasPersonalId: string) => void;
  onApplyAction: ApplyAction;
  onIgnoreAction: IgnoreAction;
  onUnignoreAction: UnignoreAction;
  schoolCode: SchoolCode | null;
}) => {
  const teamCode = schoolToTeamCode(schoolCode);
  const selectedApplicant = selected ? data.find(appt => appt.ucasPersonalId === selected) : undefined;
  return (
    <Grid.Row stretched>
      <Grid.Column width={4}>
        <SelectorListMenu applicants={data} selectedApplicantId={selected} fetching={fetching} onClick={onClick} />
      </Grid.Column>
      {data.length > 0 ? (
        <>
          <Grid.Column width={8}>
            {selectedApplicant ? (
              <SelectorListDetail
                selectedApplicant={selectedApplicant}
                fetching={fetching}
                applyAction={onApplyAction}
                ignoreAction={onIgnoreAction}
                unignoreAction={onUnignoreAction}
              />
            ) : (
              <SelectorListNoSelection />
            )}
          </Grid.Column>
          <Grid.Column width={4}>
            <SelectorListSidebar teamCode={teamCode} selectedApplicant={selectedApplicant} applications={[]} fetching={fetching} />
          </Grid.Column>
        </>
      ) : (
        <Grid.Column width={8}>
          <SelectorListNoSelection />
        </Grid.Column>
      )}
    </Grid.Row>
  );
};

export const SelectorListPage = ({ busyMessage }: Props) => {
  const [query, setQuery] = useQueryParams({
    schoolCode: StringParam,
    actionType: StringParam,
    type: StringParam,
    liveness: StringParam,
    ignored: StringParam,
  });

  const [selectedUcas, setSelectedUcas] = useQueryParam("selectedUcas", StringParam);
  const schoolCode = (query.schoolCode as SchoolCode) || null;
  // TODO: support opaque type in query param or guard
  const type = (query.type as SelectorActionType) || null;
  const liveness = (query.liveness as ApplicationLiveness) || null;
  const ignoredOption = (query.ignored as IgnoredOption) || IgnoredOptionEnum.Exclude;
  const applyAction = useApplySelectorListAction();
  const ignoreAction = useIgnoreSelectorListActions();
  const unignoreAction = useUnignoreSelectorListAction();
  const actions = useSelectorListActions({
    filters: { schoolCode, type, liveness, ignoredOption },
  });
  const fetching = actions.status === "loading";

  const invalidateSelectorListCaches = useInvalidateSelectorListCaches();

  const onInterval = () => {
    invalidateSelectorListCaches();
  };

  if (actions.status === "batchInProgress") {
    return <BatchInProgress onInterval={onInterval} />;
  }

  return (
    <Title title="Selector List Sync">
      <Container fluid>
        <Segment padded basic>
          <Dimmer.Dimmable as={Grid} dimmed={fetching}>
            <Dimmer inverted active={fetching}>
              <Segment basic padded>
                <Loader>{busyMessage || "Please wait..."}</Loader>
              </Segment>
            </Dimmer>
            <Grid.Row>
              <Grid.Column width={16}>
                <Button secondary={true} as={Link} to={`/admin/selector-list-summary${formatQueryString({ schoolCode })}`}>
                  &lt; Back to Summary
                </Button>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row style={{ zIndex: 1 }}>
              <Grid.Column width={4}>
                <SchoolDropdown
                  value={schoolCode}
                  onChange={schoolCode => {
                    setQuery({ schoolCode });
                  }}
                />
              </Grid.Column>
              <Grid.Column width={4}>
                <ActionTypeDropdown
                  value={type}
                  onChange={type => {
                    setQuery({ type });
                  }}
                />
              </Grid.Column>
              <Grid.Column width={4}>
                <LivenessDropdown value={liveness} onChange={liveness => setQuery({ liveness })} />
              </Grid.Column>
              <Grid.Column width={4}>
                <IgnoreDropdown value={ignoredOption} onChange={ignored => setQuery({ ignored })} />
              </Grid.Column>
              <Grid.Column width={3}>
                {actions.status === "success" ? (
                  <Button.Group color="blue" fluid>
                    <Dropdown button className="icon" fluid text="Bulk import" style={{ textAlign: "center" }}>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          text={`Recommended only (${actions.count.recommended})`}
                          onClick={() => {
                            actions.saveAllFiltered(true);
                          }}
                        />
                        <Dropdown.Item
                          text={`All visible (${actions.count.all})`}
                          onClick={() => {
                            actions.saveAllFiltered(false);
                          }}
                        />
                      </Dropdown.Menu>
                    </Dropdown>
                  </Button.Group>
                ) : null}
              </Grid.Column>
            </Grid.Row>
            {actions.status === "success" ? (
              <>
                {actions.data.length === 0 ? (
                  <Grid.Row>
                    <Grid.Column width={16}>
                      <Message
                        color="green"
                        icon="check"
                        size="small"
                        header="All Done"
                        content="There are no available actions matching these criteria"
                      />
                    </Grid.Column>
                  </Grid.Row>
                ) : (
                  <SelectorListPageBody
                    onClick={(ucasPersonalId: string) => {
                      setSelectedUcas(ucasPersonalId);
                    }}
                    schoolCode={schoolCode}
                    data={actions.data}
                    selected={selectedUcas}
                    fetching={fetching}
                    onApplyAction={applyAction.mutate}
                    onIgnoreAction={ignoreAction.mutate}
                    onUnignoreAction={unignoreAction.mutate}
                  />
                )}
              </>
            ) : null}
          </Dimmer.Dimmable>
        </Segment>
      </Container>
    </Title>
  );
};

import { Button, Dropdown, Grid, Table } from "semantic-ui-react";
import React from "react";
import { EnglishRequirement, Requirements } from "@qmspringboard/shared/dist/model/requirements.generated";
import { NullableNumberInput, FieldLabel } from "@qmspringboard/app/src/components/fields";
import { bands } from "./englishRequirementTiers";
import { GCSEGradeEditor } from "@qmspringboard/app/src/pages/ProgrammeEditorPage/GradeEditor";

type Props = {
  value: Requirements["english"];
  onChange: (newValue: Requirements["english"]) => void;
};

type ValueChangeEdge<A> = { value: A; onChange: (newValue: A) => void };

function IELTSOptions({ value, onChange }: ValueChangeEdge<EnglishRequirement["ielts"]>) {
  return (
    <Grid columns="equal">
      <Grid.Row>
        <Grid.Column>
          <FieldLabel label="Overall">
            <NullableNumberInput
              fluid
              value={value.overall}
              onChange={overall => {
                if (overall) {
                  onChange({ ...value, overall });
                }
              }}
            />
          </FieldLabel>
        </Grid.Column>
        <Grid.Column>
          <FieldLabel label="Listening">
            <NullableNumberInput
              fluid
              value={value.listening}
              onChange={listening => {
                if (listening) {
                  onChange({ ...value, listening });
                }
              }}
            />
          </FieldLabel>
        </Grid.Column>
        <Grid.Column>
          <FieldLabel label="Reading">
            <NullableNumberInput
              fluid
              value={value.reading}
              onChange={reading => {
                if (reading) {
                  onChange({ ...value, reading });
                }
              }}
            />
          </FieldLabel>
        </Grid.Column>
        <Grid.Column>
          <FieldLabel label="Writing">
            <NullableNumberInput
              fluid
              value={value.writing}
              onChange={writing => {
                if (writing) {
                  onChange({ ...value, writing });
                }
              }}
            />
          </FieldLabel>
        </Grid.Column>
        <Grid.Column>
          <FieldLabel label="Speaking">
            <NullableNumberInput
              fluid
              value={value.speaking}
              onChange={speaking => {
                if (speaking) {
                  onChange({ ...value, speaking });
                }
              }}
            />
          </FieldLabel>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}

export function EnglishRequirementsEditor({ value, onChange }: Props): React.ReactElement {
  return (
    <Grid width={6}>
      <Grid.Row>
        <Grid.Column mobile={16} tablet={6} computer={6}>
          <Dropdown text="Choose Preset" button fluid compact>
            <Dropdown.Menu>
              {bands.map(({ label, template }) => {
                return (
                  <Dropdown.Item
                    key={label}
                    text={label}
                    onClick={() => {
                      onChange(template);
                    }}
                  />
                );
              })}
            </Dropdown.Menu>
          </Dropdown>
        </Grid.Column>
        <Grid.Column mobile={16} tablet={10} computer={10}>
          <Button
            size="tiny"
            onClick={() => {
              onChange(null);
            }}
          >
            Remove
          </Button>
        </Grid.Column>
      </Grid.Row>
      {value ? (
        <Grid.Row>
          <Grid.Column mobile={16} tablet={16} computer={16}>
            <Table>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Qualification Type</Table.HeaderCell>
                  <Table.HeaderCell>Minimum Requirement</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                <Table.Row verticalAlign="top">
                  <Table.Cell>GCSE</Table.Cell>
                  <Table.Cell>
                    <GCSEGradeEditor
                      value={value.gcse}
                      onChange={newValue =>
                        onChange({
                          ...value,
                          gcse: newValue,
                        })
                      }
                      messages={[]}
                      readOnly={false}
                    />
                  </Table.Cell>
                </Table.Row>
                <Table.Row verticalAlign="top">
                  <Table.Cell>IELTS</Table.Cell>
                  <Table.Cell>
                    <IELTSOptions value={value.ielts} onChange={newValue => onChange({ ...value, ielts: newValue })} />
                  </Table.Cell>
                </Table.Row>
                <Table.Row verticalAlign="top">
                  <Table.Cell>TOEFL</Table.Cell>
                  <Table.Cell>
                    <IELTSOptions value={value.toefl} onChange={newValue => onChange({ ...value, toefl: newValue })} />
                  </Table.Cell>
                </Table.Row>
                <Table.Row verticalAlign="top">
                  <Table.Cell>French Bac</Table.Cell>
                  <Table.Cell>
                    <NullableNumberInput
                      label="%"
                      fluid
                      labelPosition="right"
                      value={value.frenchBac}
                      onChange={newValue => {
                        if (newValue) {
                          onChange({ ...value, frenchBac: newValue });
                        }
                      }}
                    />
                  </Table.Cell>
                </Table.Row>
                <Table.Row verticalAlign="top">
                  <Table.Cell>Indian HS</Table.Cell>
                  <Table.Cell>
                    <NullableNumberInput
                      label="%"
                      fluid
                      labelPosition="right"
                      value={value.indianHs}
                      onChange={newValue => {
                        if (newValue) {
                          onChange({ ...value, indianHs: newValue });
                        }
                      }}
                    />
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </Grid.Column>
        </Grid.Row>
      ) : null}
    </Grid>
  );
}

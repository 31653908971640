import { useWhoAmI } from "@qmspringboard/app/src/hooks/useWhoAmI";
import { createPermissions, userPermissions, Permissions } from "@qmspringboard/app/src/model/permission";

/**
 * Gather permissions from the WhoAmI data for the user. If the user is acting as another user then
 * that other user's permissions will be returned, otherwise the permissions for the logged-in user will
 * be returned.
 *
 * If the data is not yet loaded because the first call to the server hasn't completed yet then
 * this will return a set of noopPermissions. However, the hook will trigger a component refresh
 * as soon as the load is complete.
 */
export const usePermissions: () => Permissions | null = () => {
  const { data: whoAmI } = useWhoAmI();

  return whoAmI ? createPermissions(userPermissions(whoAmI.actingAs ? whoAmI.actingAs : whoAmI.user)) : null;
};

export default usePermissions;

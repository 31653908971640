import { Qualifications } from "@qmspringboard/shared/dist/model/qualifications.generated";
import React, { createContext, ReactElement, ReactNode, useContext } from "react";
import { ProgrammeCode } from "@qmspringboard/shared/dist/model/core.generated";
import { useCheckAllEntryRequirements } from "../api";
import { RequirementsCheckDTO } from "../model/types.generated";
import { Opt } from "@qmspringboard/app/src/utils";

const RequirementsCheckContext = createContext<RequirementsCheckDTO[] | undefined>(undefined);

export function useRequirementsCheckDTOs(): RequirementsCheckDTO[] | undefined {
  return useContext(RequirementsCheckContext);
}

export function useRequirementsCheckDTO(code: ProgrammeCode): RequirementsCheckDTO | undefined {
  return useContext(RequirementsCheckContext)?.find(dto => dto.programme.code === code);
}

interface RequirementsChecksProviderProps {
  qualifications: Qualifications;
  ucasPersonalId: Opt<string>;
  children: ReactNode;
}

export function RequirementsChecksProvider(props: RequirementsChecksProviderProps): ReactElement {
  const { qualifications, ucasPersonalId, children } = props;

  const query = useCheckAllEntryRequirements(qualifications, ucasPersonalId);

  return <RequirementsCheckContext.Provider value={query.data}>{children}</RequirementsCheckContext.Provider>;
}

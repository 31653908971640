import { guessFeeCategory as guessFeeCategory } from "@qmspringboard/shared/dist/model/feeCategory";
import { Location } from "history";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { match as Match } from "react-router";
import { Container, Dimmer, Grid, Loader, Segment } from "semantic-ui-react";
import ApplicantDetailsEditor from "../components/ApplicantDetailsEditor";
import ApplicantSidebar from "../components/ApplicantSidebar";
import ApplicationsEditor from "../components/ApplicationsEditor";
import EditInPlaceForm from "../components/EditInPlaceForm";
import ErrorDisplay from "../components/ErrorDisplay";
import FeeCategoryEditor from "../components/FeeCategoryEditor";
import QualificationsEditor from "../components/QualificationsEditor";
import Title from "../components/Title";
import { RequirementsChecksProvider } from "../hooks/useRequirementsChecks";
import { ApplicantDetails, ApplicantEditorState, Qualifications } from "../model/applicant";
import { Messages, subErrors } from "../model/errors";
import { Team, teamToSchoolCode } from "../model/team";
import { format as formatTrackingNumber } from "../model/trackingNumber";
import { Application, ApplicationChoice, Label, Note, SchoolCode, User } from "../model/types";
import * as applicantUpdate from "../reducers/applicantUpdate";
import { Sections } from "../reducers/applicantUpdate/state";
import * as auth from "../reducers/auth";
import * as teams from "../reducers/teams";
import { Opt, safeParseInt } from "../utils";
import { ShowModalFunc, withModal } from "../utils/modal";
import { locationQueryParams } from "../utils/queryParams";
import usePermissions from "@qmspringboard/app/src/hooks/usePermissions";
import { useCurrentUser } from "@qmspringboard/app/src/hooks/useWhoAmI";

interface Params {
  applicantId: string;
}

interface Props {
  match: Match<Params>;
  location: Location;
  dto: ApplicantEditorState;
  qualifications: Qualifications;
  fetching: boolean;
  fetched: boolean;
  saving: Sections;
  editing: Sections;
  anythingEditing: boolean;
  editingApplication: Application | null;
  messages: Messages;
  onLoad: (apptId: number, appnId?: Opt<number>) => void;
  onUpdateApplicantDetails: (newDetails: ApplicantDetails) => void;
  onStartEditingApplicantDetails: () => void;
  onCancelEditingApplicantDetails: () => void;
  onSaveApplicantDetails: () => void;
  onUpdateQualifications: (quals: Qualifications) => void;
  onStartEditingQualifications: () => void;
  onCancelEditingQualifications: () => void;
  onSaveQualifications: () => void;
  onAddApplication: (choice: ApplicationChoice) => void;
  onUpdateApplication: (appn: Application) => void;
  onRemoveApplication: (appn: Application) => void;
  onUpdateLabel: (oldLabel: Label, neLabel: Label) => void;
  onAddNote: (author: User, schoolCode?: Opt<SchoolCode>) => void;
  onSaveNote: (oldNote: Note, newNote: Note) => void;
  onUpdateNote: (oldNote: Note, newNote: Note) => void;
  onDeleteNote: (note: Note) => void;
  selectedApplication: Application | null;
  selectedApplicationInitial: Application | null;
  selectedApplicationIndex: number | null;
  setSelectedApplication: (application: Application) => void;
  isDirty: () => boolean;
  currentTeam: Team;
  allTeams: Team[];
  showModal: ShowModalFunc;
}

const ApplicantUpdatePage = (props: Props) => {
  const currentUser = useCurrentUser();

  const handleAddNote = () => {
    const { onAddNote, currentTeam } = props;
    if (currentUser) {
      onAddNote(currentUser, teamToSchoolCode(currentTeam.code));
    } else {
      console.error("Attempt to add note without currentUser");
    }
  };

  const handleDeleteNote = (note: Note) => {
    const { onDeleteNote } = props;
    props.showModal("confirm", {
      onPrimaryClick: () => onDeleteNote(note),
      content: "Are you sure you want to delete this note?",
    });
  };

  const [applicantId, setApplicantId] = useState<Opt<number>>();

  const params = props.match.params;
  const location = props.location;
  const onLoad = props.onLoad;

  useEffect(() => {
    const { applicantId: applicantIdString } = params;
    const { application: applicationIdString } = locationQueryParams(location);
    const applicantId = safeParseInt(applicantIdString, undefined);
    if (applicantId != null) {
      onLoad(applicantId, safeParseInt(applicationIdString, undefined));
      setApplicantId(applicantId);
    }
  }, [location, onLoad, params]);

  useEffect(() => {
    const { applicantId: newApplicantIdString } = params;

    const newApplicantId = safeParseInt(newApplicantIdString, props.dto.applicant.id);

    if (newApplicantId !== applicantId) {
      props.onLoad(newApplicantId, safeParseInt(locationQueryParams(props.location).application, undefined));
      setApplicantId(newApplicantId);
    }
  }, [props, applicantId, params]);

  const wrappedGuessFeeCategory = () =>
    guessFeeCategory(props.dto.applicant.details.feeStatus, props.dto.applicant.details.ukResidency, props.dto.applicant.details.ukImmigrationStatus);

  const permissions = usePermissions();

  const {
    dto,
    saving,
    editing,
    anythingEditing,
    editingApplication,
    fetching,
    messages,
    onUpdateApplicantDetails,
    onStartEditingApplicantDetails,
    onCancelEditingApplicantDetails,
    onSaveApplicantDetails,
    onUpdateQualifications,
    onStartEditingQualifications,
    onCancelEditingQualifications,
    onSaveQualifications,
    onAddApplication,
    onRemoveApplication,
    onUpdateApplication,
    onUpdateLabel,
    onSaveNote,
    onUpdateNote,
    qualifications,
    selectedApplication,
    selectedApplicationInitial,
    selectedApplicationIndex,
    setSelectedApplication,
    currentTeam,
    allTeams,
    isDirty,
  } = props;

  const splitErrors = subErrors(messages, {
    applicant: true,
    applications: true,
    transfers: true,
    _email_: true,
  });

  const detailsErrors = subErrors(splitErrors.applicant, {
    details: true,
    qualifications: true,
  });

  if (!permissions) {
    return <Loader>Loading</Loader>;
  }

  const canEditApplicantDetails = permissions.canUpdateApplicantDetails();

  const canEditQualifications = permissions.canUpdateQualifications();

  const canEditAnyApplications = true;

  return (
    <Title title={dto == null || dto.applicant.id < 0 ? "Loading" : formatTrackingNumber(dto.applicant.id)}>
      <Container>
        <Dimmer.Dimmable as={Container}>
          <Dimmer active={fetching} inverted>
            <Loader>Loading...</Loader>
          </Dimmer>

          <RequirementsChecksProvider qualifications={dto.applicant.qualifications} ucasPersonalId={dto.applicant.details.ucasPersonalId}>
            <Grid>
              <Grid.Row>
                <Grid.Column width={12}>
                  <ErrorDisplay messages={splitErrors._rest_} />
                  <ErrorDisplay messages={detailsErrors._rest_} />

                  <EditInPlaceForm
                    editing={editing.details}
                    saving={saving.details}
                    messages={detailsErrors.details}
                    readOnly={!canEditApplicantDetails}
                    isDirty={isDirty}
                    anythingEditing={anythingEditing}
                    onEdit={onStartEditingApplicantDetails}
                    onCancel={onCancelEditingApplicantDetails}
                    onSave={onSaveApplicantDetails}
                    style={{ marginTop: 0 }}
                  >
                    {dto && (
                      <>
                        <ApplicantDetailsEditor
                          value={dto.applicant.details}
                          duplicates={dto.duplicates}
                          messages={detailsErrors.details}
                          readOnly={!editing.details}
                          onChange={onUpdateApplicantDetails}
                        />
                        <FeeCategoryEditor
                          value={dto.applicant.details}
                          messages={detailsErrors.details}
                          readOnly={!editing.details}
                          onChange={onUpdateApplicantDetails}
                        />
                      </>
                    )}
                  </EditInPlaceForm>

                  <EditInPlaceForm
                    editing={editing.qualifications}
                    saving={saving.qualifications}
                    messages={detailsErrors.qualifications}
                    readOnly={!canEditQualifications}
                    isDirty={isDirty}
                    anythingEditing={anythingEditing}
                    onEdit={onStartEditingQualifications}
                    onCancel={onCancelEditingQualifications}
                    onSave={onSaveQualifications}
                  >
                    {dto && (
                      <QualificationsEditor
                        value={dto.applicant.qualifications}
                        messages={detailsErrors.qualifications}
                        readOnly={!editing.qualifications || !canEditQualifications}
                        onChange={onUpdateQualifications}
                      />
                    )}
                  </EditInPlaceForm>

                  <Segment>
                    <ApplicationsEditor
                      currentTeam={currentTeam}
                      allTeams={allTeams}
                      permissions={permissions}
                      value={dto.applications}
                      anythingEditing={anythingEditing}
                      editingApplication={editingApplication}
                      readOnly={!canEditAnyApplications}
                      qualifications={qualifications}
                      guessedFeeCategory={wrappedGuessFeeCategory()}
                      applicantSaved
                      messages={splitErrors.applications}
                      emailMessages={editingApplication != null ? splitErrors._email_ : []}
                      onAddApplication={onAddApplication}
                      selectedApplication={selectedApplication}
                      selectedApplicationInitial={selectedApplicationInitial}
                      selectedApplicationIndex={selectedApplicationIndex}
                      setSelectedApplication={setSelectedApplication}
                      onRemoveApplication={onRemoveApplication}
                      onUpdateApplication={onUpdateApplication}
                      canRemoveApplications={false}
                    />
                  </Segment>
                </Grid.Column>
                <Grid.Column width={4}>
                  <ApplicantSidebar
                    dto={dto}
                    permissions={permissions}
                    currentTeam={currentTeam}
                    allTeams={allTeams}
                    user={currentUser}
                    onUpdateLabel={onUpdateLabel}
                    onAddNote={handleAddNote}
                    onSaveNote={onSaveNote}
                    onUpdateNote={onUpdateNote}
                    onDeleteNote={handleDeleteNote}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </RequirementsChecksProvider>
        </Dimmer.Dimmable>
      </Container>
    </Title>
  );
};

export default connect(
  state => ({
    currentUser: auth.currentUser(state),
    dto: applicantUpdate.dto(state),
    fetching: applicantUpdate.fetching(state),
    fetched: applicantUpdate.fetched(state),
    saving: applicantUpdate.saving(state),
    editing: applicantUpdate.editing(state),
    editingApplication: applicantUpdate.editingApplication(state),
    messages: applicantUpdate.messages(state),
    qualifications: applicantUpdate.qualifications(state),
    selectedApplication: applicantUpdate.selectedApplication(state),
    selectedApplicationInitial: applicantUpdate.selectedApplicationInitial(state),
    selectedApplicationIndex: applicantUpdate.selectedApplicationIndex(state),
    isDirty: applicantUpdate.anythingDirty(state),
    currentTeam: teams.currentTeam(state),
    allTeams: teams.allTeams(state),
  }),
  {
    onLoad: applicantUpdate.load,
    onUpdateApplicantDetails: applicantUpdate.updateApplicantDetails,
    onStartEditingApplicantDetails: applicantUpdate.startEditingApplicantDetails,
    onCancelEditingApplicantDetails: applicantUpdate.cancelEditingApplicantDetails,
    onSaveApplicantDetails: applicantUpdate.saveApplicantDetails,
    onUpdateQualifications: applicantUpdate.updateQualifications,
    onStartEditingQualifications: applicantUpdate.startEditingQualifications,
    onCancelEditingQualifications: applicantUpdate.cancelEditingQualifications,
    onSaveQualifications: applicantUpdate.saveQualifications,
    onAddApplication: applicantUpdate.addApplication,
    setSelectedApplication: applicantUpdate.setSelectedApplication,
    onUpdateApplication: applicantUpdate.updateApplication,
    onRemoveApplication: applicantUpdate.removeApplication,
    onUpdateLabel: applicantUpdate.saveLabel,
    onAddNote: applicantUpdate.addNote,
    onSaveNote: applicantUpdate.saveNote,
    onUpdateNote: applicantUpdate.updateNote,
    onDeleteNote: applicantUpdate.deleteNote,
  },
)(withModal(ApplicantUpdatePage));

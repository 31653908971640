import React from "react";
import { Button, Grid, Header, Segment, SemanticCOLORS } from "semantic-ui-react";
import { ConditionEditor, IconButton } from "@qmspringboard/app/src/pages/ProgrammeEditorPage/ConditionEditor";
import { ItemEditorProps } from "@qmspringboard/app/src/pages/ProgrammeEditorPage/types";
import { RequirementsAlternative } from "@qmspringboard/shared/src/model/requirements.generated";
import { indexedErrors, subErrors } from "@qmspringboard/app/src/model/errors";
import update from "immutability-helper";
import { DEFAULT_CONDITION, DEFAULT_REQUIREMENTS_CASE } from "@qmspringboard/shared/src/model/requirements";
import { OverallRequirementEditor } from "@qmspringboard/app/src/pages/ProgrammeEditorPage/OverallRequirementsEditor";
import ErrorDisplay from "@qmspringboard/app/src/components/ErrorDisplay";
import { Message } from "@qmspringboard/shared/src/model/core.generated";

function RootContainer({
  onRemove,
  actions,
  header,
  children,
  color,
}: React.PropsWithChildren<{
  color?: SemanticCOLORS;
  onRemove?: () => void;
  header?: React.ReactNode;
  actions?: React.ReactNode;
}>) {
  return (
    <Segment color={color}>
      <Grid>
        {onRemove || header ? (
          <Grid.Row>
            {header ? <Grid.Column width={onRemove ? 14 : 16}>{header}</Grid.Column> : null}
            {onRemove ? (
              <Grid.Column width={header ? 2 : 16} textAlign="right">
                <IconButton onClick={onRemove} />
              </Grid.Column>
            ) : null}
          </Grid.Row>
        ) : null}
        {children ? (
          <Grid.Row>
            <Grid.Column width={16}>{children}</Grid.Column>
          </Grid.Row>
        ) : null}
        <Grid.Row>{actions}</Grid.Row>
      </Grid>
    </Segment>
  );
}

function RequirementsAlternativeEditor({
  value,
  onChange,
  onRemove,
  messages,
  path,
  color,
  ...passProps
}: ItemEditorProps<RequirementsAlternative> & {
  color?: SemanticCOLORS;
  onRemove?: () => void;
}) {
  const { overall, conditions } = value;

  const splitErrors = subErrors(messages, {
    overall: true,
    conditions: true,
  });

  const conditionErrors = indexedErrors(splitErrors.conditions, conditions.length);

  const actions = (
    <Grid.Column>
      <Button
        onClick={() =>
          onChange(
            update(value, {
              conditions: { $push: [DEFAULT_CONDITION] },
            }),
          )
        }
      >
        Add a Condition
      </Button>
    </Grid.Column>
  );

  const header = (
    <OverallRequirementEditor
      {...passProps}
      path={`${path}-grades`}
      value={overall}
      messages={splitErrors.overall}
      onChange={overall => onChange({ ...value, overall })}
    />
  );
  return (
    <>
      <RootContainer color={color} onRemove={onRemove} actions={actions} header={header}>
        {conditions.map((condition, index) => (
          <ConditionEditor
            key={index}
            {...passProps}
            path={`${path}-condition`}
            onRemove={() => onChange(update(value, { conditions: { $splice: [[index, 1]] } }))}
            messages={conditionErrors[index]}
            value={condition}
            onChange={condition => onChange(update(value, { conditions: { [index]: { $set: condition } } }))}
          />
        ))}
      </RootContainer>
      <ErrorDisplay messages={splitErrors._rest_} />
    </>
  );
}

export interface RequirementsAlternativesEditorProps {
  readOnly: boolean;
  path: string;
  alternatives: RequirementsAlternative[];
  contextualAlternatives: RequirementsAlternative[];
  messages: Message[];
  onChange: (altenatives: RequirementsAlternative[], contextualAlternatives: RequirementsAlternative[]) => void;
}

export function RequirementsAlternativesEditor({
  alternatives,
  contextualAlternatives,
  onChange,
  messages,
  ...passProps
}: RequirementsAlternativesEditorProps) {
  const splitErrors = indexedErrors(messages, alternatives.length);

  const defaultContextualAlternative = alternatives.length > 0 ? alternatives[0] : DEFAULT_REQUIREMENTS_CASE;

  return (
    <Grid>
      {alternatives.map((alt, index) => (
        <Grid.Row key={index}>
          <Grid.Column width={16}>
            <RequirementsAlternativeEditor
              value={alt}
              onChange={alt => onChange(update(alternatives, { [index]: { $set: alt } }), contextualAlternatives)}
              onRemove={() => onChange(update(alternatives, { $splice: [[index, 1]] }), contextualAlternatives)}
              messages={splitErrors[index]}
              {...passProps}
            />
          </Grid.Column>
        </Grid.Row>
      ))}
      <Grid.Row>
        <Grid.Column width={16}>
          <Button onClick={() => onChange(update(alternatives, { $push: [DEFAULT_REQUIREMENTS_CASE] }), contextualAlternatives)}>
            Add Alternative Set of Requirements
          </Button>
          {contextualAlternatives.length == 0 && (
            <Button color="teal" onClick={() => onChange(alternatives, update(contextualAlternatives, { $push: [defaultContextualAlternative] }))}>
              Add A Set of Contextual Requirements
            </Button>
          )}
        </Grid.Column>
      </Grid.Row>
      {contextualAlternatives.length > 0 && (
        <>
          <Header>Contextual Entry Requirements</Header>
          {contextualAlternatives.map((alt, index) => (
            <Grid.Row key={index}>
              <Grid.Column width={16}>
                <RequirementsAlternativeEditor
                  value={alt}
                  onChange={alt => onChange(alternatives, update(contextualAlternatives, { [index]: { $set: alt } }))}
                  onRemove={() => onChange(alternatives, update(contextualAlternatives, { $splice: [[index, 1]] }))}
                  messages={splitErrors[index]}
                  color="teal"
                  {...passProps}
                />
              </Grid.Column>
            </Grid.Row>
          ))}
          <Grid.Row>
            <Grid.Column width={16}>
              <Button color="teal" onClick={() => onChange(alternatives, update(contextualAlternatives, { $push: [defaultContextualAlternative] }))}>
                Add An Alternative Set of Contextual Requirements
              </Button>
            </Grid.Column>
          </Grid.Row>
        </>
      )}
    </Grid>
  );
}

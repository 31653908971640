import React from "react";
import { ApplicationLiveness, ApplicationLivenessEnum, IgnoredOptionEnum } from "../../model/enums";
import { SelectorActionType } from "../../model/selectorList";
import { Opt } from "../../utils";
import DropdownField, { DropdownOption, DropdownOptions } from "../../components/fields/DropdownField";
import { IgnoredOption } from "../../model/selectorList.generated";

const createOption = <A extends string>(value: Opt<A>, label: string): DropdownOption<Opt<A>> => ({
  key: value == null ? "--blank--" : value.toLocaleString(),
  value,
  label,
});

const actionTypeDropdownOptions: DropdownOptions<Opt<SelectorActionType>> = [
  createOption<SelectorActionType>(null, "-- Show actions of all types --"),
  createOption("LinkApplication", "Link existing applications only"),
  createOption("ImportApplication", "Import new applicants/applications only"),
  createOption("ProgrammeChange", "Programme changes only"),
  createOption("FeeStatusChange", "Fee status changes only"),
  createOption("StudentIdChange", "Student ID changes only"),
  createOption("SitsHomeEmailChange", "Email changes only"),
  createOption("NameChange", "Name changes only"),
  createOption("TelephoneChange", "Telephone changes only"),
  createOption("NationalityChange", "Nationality changes only"),
  createOption("InitialDecisionChange", "Initial decision changes only"),
  createOption("InitialResponseChange", "Initial response changes only"),
  createOption("ConfirmationDecisionChange", "Confirmation decision changes only"),
  createOption("ConfirmationResponseChange", "Confirmation response changes only"),
  createOption("ClearingDecisionChange", "Clearing decision changes only"),
  createOption("ClearingResponseChange", "Clearing response changes only"),
  createOption("ApplicationStatusChange", "Application status changes only"),
  createOption("EntryPointChange", "Entry point changes only"),
  createOption("CocoChange", "COCO changes only"),
];

export const ActionTypeDropdown = ({ value, onChange }: { value: Opt<SelectorActionType>; onChange: (type: Opt<SelectorActionType>) => void }) => (
  <DropdownField
    fluid
    placeholder="Showing actions of all types"
    value={value}
    onChange={onChange}
    options={actionTypeDropdownOptions}
    readOnly={false}
  />
);

const livenessDropdownOptions: DropdownOptions<Opt<ApplicationLiveness>> = [
  createOption<ApplicationLiveness>(null, "Show actions for all applications"),
  createOption(ApplicationLivenessEnum.Live, "Live applications"),
  createOption(ApplicationLivenessEnum.Successful, "Successful applications"),
  createOption(ApplicationLivenessEnum.Failed, "Failed applications"),
];

export const LivenessDropdown = ({ value, onChange }: { value: Opt<ApplicationLiveness>; onChange: (value: Opt<ApplicationLiveness>) => void }) => (
  <DropdownField
    fluid
    placeholder="-- Showing actions for all applicants --"
    value={value}
    onChange={onChange}
    options={livenessDropdownOptions}
    readOnly={false}
  />
);

const ignoreDropdownOptions: DropdownOptions<Opt<IgnoredOption>> = [
  createOption(IgnoredOptionEnum.Exclude, "Exclude ignored actions"),
  createOption(IgnoredOptionEnum.Include, "Include ignored actions"),
  createOption(IgnoredOptionEnum.Only, "Only show ignored actions"),
];

export const IgnoreDropdown = ({ value, onChange }: { value: Opt<IgnoredOption>; onChange: (value: Opt<IgnoredOption>) => void }) => (
  <DropdownField fluid value={value} onChange={onChange} options={ignoreDropdownOptions} readOnly={false} />
);

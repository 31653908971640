import * as React from "react";
import { useApplySelectorListRecommendedActions, useSelectorListSummary } from "@qmspringboard/app/src/api";
import { SchoolCode } from "@qmspringboard/shared/src/model/core.generated";
import { SetQuery, StringParam, useQueryParams } from "use-query-params";
import { Button, Container, Dimmer, Grid, Loader, Message, Segment, Table, TableFooter } from "semantic-ui-react";
import Title from "@qmspringboard/app/src/components/Title";
import { SelectorListActionSummaries, SelectorListActionSummary, SummaryTotals } from "@qmspringboard/app/src/model/selectorList.generated";
import { SchoolDropdown } from "@qmspringboard/app/src/components/SchoolDropdown";
import { IgnoreDropdown } from "../SelectorListPage/SelectorListDropdowns";
import { IgnoredOption } from "@qmspringboard/shared/src/model/enums.generated";
import { actionName } from "@qmspringboard/app/src/pages/SelectorListSummaryPage/actionName";
import { formatQueryString } from "@qmspringboard/app/src/utils/queryParams";
import { Link } from "react-router-dom";
import { UseMutationResult } from "react-query";
import { BatchInProgress } from "@qmspringboard/app/src/pages/SelectorListSummaryPage/BatchInProgress";

const queryParams = {
  schoolCode: StringParam,
  type: StringParam,
  liveness: StringParam,
  ignore: StringParam,
};

interface Props {
  summary: SelectorListActionSummaries;
  schoolCode: SchoolCode | null;
  ignored: IgnoredOption;
  setQuery: SetQuery<typeof queryParams>;
  applyRecommended: UseMutationResult<void, unknown, { type: string | null }>;
}

function OptionsSelector({
  setQuery,
  schoolCode,
  ignored,
}: {
  setQuery: SetQuery<typeof queryParams>;
  schoolCode: SchoolCode | null;
  ignored: IgnoredOption;
}) {
  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={6}>
          <SchoolDropdown value={schoolCode} onChange={schoolCode => setQuery({ schoolCode })} />
        </Grid.Column>
        <Grid.Column width={4}>
          <IgnoreDropdown value={ignored} onChange={ignore => setQuery({ ignore })} />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}

function count(totals: SummaryTotals, ignored: IgnoredOption): number {
  return ignored == "exclude" ? totals.nonIgnored : ignored == "include" ? totals.ignored + totals.nonIgnored : totals.ignored;
}

function summaryForSchool(summaries: SelectorListActionSummaries, schoolCode: SchoolCode | null): SelectorListActionSummary[] {
  if (schoolCode) {
    return summaries.schoolSummaries[schoolCode];
  } else {
    return summaries.totals;
  }
}

function SummaryTable(props: Props) {
  const { summary, schoolCode, ignored, applyRecommended } = props;

  const applying = applyRecommended.isLoading;

  return (
    <Dimmer.Dimmable as={Grid} dimmed={applying}>
      <Dimmer inverted active={applying}>
        <Segment basic padded>
          <Loader>Please wait...</Loader>
        </Segment>
      </Dimmer>
      <Table celled verticalAlign={"middle"}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Action Type</Table.HeaderCell>
            <Table.HeaderCell>Recommended</Table.HeaderCell>
            <Table.HeaderCell>Non-Recommended</Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {summaryForSchool(summary, schoolCode)
            // Ensure a stable ordering of the action types when displayed in the table
            .sort((summary1, summary2) => summary1.actionType.localeCompare(summary2.actionType))
            .map((summary: SelectorListActionSummary) => {
              const type = summary.actionType;
              const recommendedCount = count(summary.recommendedTotals, ignored);
              const nonRecommendedCount = count(summary.nonRecommendedTotals, ignored);

              return (
                <Table.Row key={type}>
                  <Table.Cell>{actionName(type)}</Table.Cell>
                  <Table.Cell>
                    <Grid columns={2}>
                      <Grid.Column verticalAlign={"middle"}>{recommendedCount}</Grid.Column>
                      <Grid.Column>
                        {recommendedCount > 0 && (
                          <Button primary positive onClick={() => applyRecommended.mutate({ type: type })}>
                            Apply Recommended
                          </Button>
                        )}
                      </Grid.Column>
                    </Grid>
                  </Table.Cell>
                  <Table.Cell>{nonRecommendedCount}</Table.Cell>
                  <Table.Cell>
                    <Button secondary={true} as={Link} to={`/admin/selector-list${formatQueryString({ ignored, schoolCode, type: type })}`}>
                      Review
                    </Button>
                  </Table.Cell>
                </Table.Row>
              );
            })}
        </Table.Body>
        <TableFooter>
          <Table.Row>
            <Table.HeaderCell>Total</Table.HeaderCell>
            <Table.HeaderCell>
              <Grid columns={2}>
                <Grid.Column verticalAlign={"middle"}>
                  {summaryForSchool(summary, schoolCode)
                    .map(summary => count(summary.recommendedTotals, ignored))
                    .reduce((total: number, next) => total + next, 0)}
                </Grid.Column>
                <Grid.Column>
                  <Button primary onClick={() => applyRecommended.mutate({ type: null })}>
                    Apply All Recommended
                  </Button>
                </Grid.Column>
              </Grid>
            </Table.HeaderCell>
            <Table.HeaderCell>
              {summaryForSchool(summary, schoolCode)
                .map(summary => count(summary.nonRecommendedTotals, ignored))
                .reduce((total: number, next) => total + next, 0)}
            </Table.HeaderCell>
            <Table.HeaderCell>
              <Button secondary={true} as={Link} to={`/admin/selector-list${formatQueryString({ ignored, schoolCode })}`}>
                Review
              </Button>
            </Table.HeaderCell>
          </Table.Row>
        </TableFooter>
      </Table>
    </Dimmer.Dimmable>
  );
}

function SelectorListSummaryPage(props: Props) {
  return (
    <Title title="Selector List Sync">
      <Container>
        <OptionsSelector {...props} />
        <SummaryTable {...props} />
      </Container>
    </Title>
  );
}

const PageLoader = () => {
  const [query, setQuery] = useQueryParams(queryParams);

  const schoolCode = (query.schoolCode as SchoolCode) || null;
  const ignored = (query.ignore as IgnoredOption) || "exclude";
  const summary = useSelectorListSummary();
  const applyRecommended = useApplySelectorListRecommendedActions(schoolCode, ignored);
  const fetching = summary.isLoading || summary.isFetching;

  const onInterval = () => {
    console.log("onInterval");
    summary.refetch();
  };

  if (summary?.data?.batchInProgress) {
    return <BatchInProgress onInterval={onInterval} />;
  }

  if (fetching) {
    return (
      <Title title="Selector List Sync">
        <Container>
          <Segment basic padded>
            <Loader active={true}>Please wait...</Loader>
          </Segment>
        </Container>
      </Title>
    );
  }

  if (summary.isSuccess) {
    if (summaryForSchool(summary.data, schoolCode).length === 0) {
      return (
        <Container>
          <OptionsSelector setQuery={setQuery} schoolCode={schoolCode} ignored={ignored} />
          <Table celled verticalAlign={"middle"}>
            <Table.Body>
              <Table.Row>
                <Grid.Row>
                  <Grid.Column width={16}>
                    <Message
                      color="green"
                      icon="check"
                      size="small"
                      header="All Done"
                      content="There are no available actions matching these criteria"
                    />
                  </Grid.Column>
                </Grid.Row>
              </Table.Row>
            </Table.Body>
          </Table>
        </Container>
      );
    } else {
      return (
        <SelectorListSummaryPage
          summary={summary.data}
          schoolCode={schoolCode}
          ignored={ignored}
          setQuery={setQuery}
          applyRecommended={applyRecommended}
        ></SelectorListSummaryPage>
      );
    }
  }

  return <></>;
};

export default PageLoader;

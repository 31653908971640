import * as React from "react";
import { UseMutateFunction } from "react-query";
import { useApplySelectorListActions, useSelectorList } from "../api";
import { selectorActionFilterPredicate, SelectorActionFilters } from "../model/selectorList";
import { SelectorAction, SelectorListApplicant, SelectorListRowActions } from "../model/types";

type FilteredSelectorListActionsByApplicant = {
  applicants: SelectorListApplicant[];
  countAll: number;
  countRecommended: number;
};

const filterSelectorRows = (rows: SelectorListRowActions[], cond: (action: SelectorAction) => boolean): SelectorListRowActions[] => {
  const result: SelectorListRowActions[] = [];
  rows.forEach(row => {
    const actionsForRow: SelectorAction[] = [];
    row.actions.forEach(action => {
      if (cond(action)) actionsForRow.push(action);
    });

    if (actionsForRow.length > 0) result.push({ ...row, actions: actionsForRow });
  });
  return result;
};

const ungroupSelectorActions = (data: SelectorListApplicant[]): SelectorListRowActions[] => {
  const result: SelectorListRowActions[] = [];
  data.forEach(applicant => {
    const filteredRows = filterSelectorRows(applicant.selectorRows, action => {
      // don't bulk apply non-recommended Link/Import application actions
      // see https://github.com/qmspringboard/qmspringboard/issues/1725
      return !((action.type === "LinkApplication" || action.type === "ImportApplication") && !action.recommended);
    });

    result.push(...filteredRows);
  });
  return result;
};

const filterSelectorListApplicants = (data: SelectorListApplicant[], filters: SelectorActionFilters): FilteredSelectorListActionsByApplicant => {
  // This uses mutable arrays to filter the incoming array of SelectorListApplicants in a single pass:

  const pred = selectorActionFilterPredicate(filters);

  const applicants: SelectorListApplicant[] = [];
  let countAll = 0;
  let countRecommended = 0;

  data.forEach((appt: SelectorListApplicant) => {
    const selectorRows: SelectorListRowActions[] = [];

    appt.selectorRows.forEach((row: SelectorListRowActions) => {
      const actions: SelectorAction[] = [];

      row.actions.forEach((action: SelectorAction) => {
        if (
          pred({
            action,
            liveness: row.linkedApplication && row.linkedApplication.liveness,
          })
        ) {
          actions.push(action);

          countAll = countAll + 1;
          countRecommended = action.recommended ? countRecommended + 1 : countRecommended;
        }
      });

      if (actions.length !== 0) {
        selectorRows.push({ ...row, actions });
      }
    });

    if (selectorRows.length !== 0) {
      applicants.push({ ...appt, selectorRows });
    }
  });

  return {
    ...data,
    applicants,
    countAll,
    countRecommended,
  };
};
export type ApplyAction = UseMutateFunction<unknown, unknown, SelectorAction, unknown>;

export type IgnoreAction = UseMutateFunction<unknown, unknown, SelectorAction, unknown>;

export type UnignoreAction = UseMutateFunction<unknown, unknown, SelectorAction, unknown>;

export type HookReturn =
  | { status: "error"; data: null; error: unknown }
  | { status: "loading"; data: null }
  | { status: "batchInProgress"; data: null }
  | {
      status: "success";
      data: SelectorListApplicant[];
      saveAllFiltered: (recommended: boolean) => void;
      count: { all: number; recommended: number };
    };

export function useSelectorListActions({ filters }: { filters: SelectorActionFilters }): HookReturn {
  const query = useSelectorList({ type: filters.type || null, schoolCode: filters.schoolCode || null, ignored: filters.ignoredOption });
  const applyMany = useApplySelectorListActions();

  return React.useMemo(() => {
    switch (query.status) {
      case "error": {
        return {
          status: "error",
          data: null,
          error: query.error,
        };
      }
      case "idle":
      case "loading": {
        return {
          status: "loading",
          data: null,
        };
      }
      case "success": {
        if (query.data.batchInProgress) {
          return { status: "batchInProgress", data: null };
        } else {
          const result = filterSelectorListApplicants(query.data.actions, filters);

          const saveAllFiltered = (recommended: boolean) => {
            const all = ungroupSelectorActions(result.applicants);
            const actions = recommended ? filterSelectorRows(all, action => action.recommended) : all;

            applyMany.mutate(actions);
          };

          switch (applyMany.status) {
            case "success":
            case "idle": {
              return {
                status: "success",
                data: result.applicants,
                saveAllFiltered,
                count: {
                  all: result.countAll,
                  recommended: result.countRecommended,
                },
              };
            }
            case "error": {
              return {
                status: "error",
                data: null,
                error: applyMany.error,
              };
            }
            case "loading": {
              return {
                status: "loading",
                data: null,
              };
            }
          }
        }
      }
    }
  }, [query, filters, applyMany]);
}

import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Button, Container, Grid } from "semantic-ui-react";
import * as pot from "../api/pot";
import ApplicationList from "../components/ApplicationList";
import { FieldLabel } from "../components/fields";
import { SearchProps, withSearch } from "../components/SearchHOC";
import Title from "../components/Title";
import { emptySearchResults } from "../model/search";
import { ApplicationSummary } from "../model/types";
import * as applicationSearch from "../reducers/applicationSearch";
import { RouteComponentProps } from "react-router";

interface Props extends SearchProps<ApplicationSummary>, RouteComponentProps {
  //
}

class ApplicantSearchPage extends React.Component<Props> {
  render() {
    const { searchComponent, pagerComponent, q } = this.props;
    const results = pot.get(this.props.results);
    const fetching = pot.fetching(this.props.results);
    const fetchError = pot.error(this.props.results);

    return (
      <Title title="Take a Call">
        <Container>
          <Grid columns="equal">
            <Grid.Row>
              <Grid.Column />
              <Grid.Column mobile={16} tablet={10} computer={8}>
                <FieldLabel label="Enter a UCAS number, tracking number, name, or email address...">{searchComponent}</FieldLabel>
              </Grid.Column>
              <Grid.Column />
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={16}>
                <p>Select an applicant from the list below...</p>
                <ApplicationList results={results || emptySearchResults()} fetching={fetching} fetchError={fetchError} />
                {pagerComponent}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column />
              {/* Hide the "Add new" button until the user has searched, to reduce risk of duplicate applicant records  */}
              {results && (
                <Grid.Column mobile={8} tablet={6} computer={4} textAlign="center">
                  <FieldLabel label="Search thoroughly before adding a new applicant.">
                    <Button basic fluid primary as={Link} to={`/applicant/new${q ? `?q=${q}` : ""}`}>
                      Add a new applicant
                    </Button>
                  </FieldLabel>
                </Grid.Column>
              )}
              <Grid.Column />
            </Grid.Row>
          </Grid>
        </Container>
      </Title>
    );
  }
}

export default connect(
  state => ({
    results: applicationSearch.results(state),
    fetching: applicationSearch.fetching(state),
    count: 5,
    emptyByDefault: true,
    searchBySchool: false,
    placeholder: "UCAS Personal ID",
  }),
  {
    search: applicationSearch.search,
    reset: applicationSearch.reset,
  },
)(withSearch(ApplicantSearchPage, 5));

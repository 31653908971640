import React, { useState, useEffect } from "react";
import { useLocation } from "react-router";

import Title from "../components/Title";
import { Container, Button, Table, Segment, Grid, Modal, Dropdown } from "semantic-ui-react";
import { useSearchAuditLog, useSearchUsers } from "../api";
import ReactQueryContainer from "../components/ReactQueryContainer";
import DateTime from "../components/DateTime";
import { AuditEvent } from "../model/auditTrail.generated";
import { User } from "../model/types.generated";
import Sorter from "../components/Sorter";
import { UserDropdown } from "../components/UserDropdown";
import Pager from "../components/Pager";
import { ready } from "../api/pot";
import { formatNameWithInitials } from "../model/name";
import { AuditEventView } from "../components/ModalAuditTrail";
import { AuditEventType, AuditEventTypeEnum } from "../model/enums";

function useQueryParams() {
  const search = useLocation().search;
  const urlSearchParams = new URLSearchParams(search);
  const params = Object.fromEntries(urlSearchParams.entries());
  return params;
}

export function AuditList() {
  const [selectedUser, setSelectedUser] = useState<User | undefined>(undefined);
  const [selectedEventType, setSelectedEventType] = useState<AuditEventType | undefined>(undefined);

  const [start, setStart] = useState(0);
  const { sortby, sortdir, page } = useQueryParams();
  const ROWS_PER_PAGE = 25;

  useEffect(() => {
    const p = Number(page);
    isNaN(p) ? setStart(0) : setStart((p - 1) * ROWS_PER_PAGE);
  }, [page]);

  const events = useSearchAuditLog(selectedUser?.id, selectedEventType, { sortby, sortdir, start, count: ROWS_PER_PAGE });
  const users = useSearchUsers(undefined, { count: 9999 });

  return (
    <>
      <Grid>
        <Grid.Row>
          <Grid.Column width={4}>
            <ReactQueryContainer query={users}>
              {results => <UserDropdown users={results.items} value={selectedUser} onChange={setSelectedUser} />}
            </ReactQueryContainer>
          </Grid.Column>
          <Grid.Column width={4}>
            <AuditTypeDropdown value={selectedEventType} onChange={setSelectedEventType} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <ReactQueryContainer query={events}>
        {results => {
          return (
            <>
              <Table>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>
                      <Sorter sortBy="when" label="When" />
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      <Sorter sortBy="who" label="Who" />
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      <Sorter sortBy="what" label="What" />
                    </Table.HeaderCell>
                    <Table.HeaderCell>Applicant</Table.HeaderCell>
                    <Table.HeaderCell>Application</Table.HeaderCell>
                    <Table.HeaderCell>Details</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>{results.items.length === 0 ? renderNoItemsRow() : results.items.map(renderRow)}</Table.Body>
              </Table>
              <Pager pageSize={ROWS_PER_PAGE} results={ready(results)} />
            </>
          );
        }}
      </ReactQueryContainer>
    </>
  );
}

const renderNoItemsRow = () => {
  return (
    <Table.Row key="noitems">
      <Table.Cell colSpan={14} textAlign="center">
        <Segment basic>No events to show</Segment>
      </Table.Cell>
    </Table.Row>
  );
};

const renderRow = (e: AuditEvent) => {
  return (
    <Table.Row key={e.id}>
      <Table.Cell>
        <DateTime iso={e.timestamp} />
      </Table.Cell>
      <Table.Cell>
        <Who id={e.userId} name={e.userName} />
      </Table.Cell>
      <Table.Cell>
        <ActionType type={e.action.type} />
      </Table.Cell>
      <Table.Cell>
        <Applicant event={e} />
      </Table.Cell>
      <Table.Cell>
        <Application event={e} />
      </Table.Cell>
      <Table.Cell>
        <Modal trigger={<Button size="small">Show</Button>} content={<AuditEventView event={e} />} />
      </Table.Cell>
    </Table.Row>
  );
};

function Who({ id, name }: { id?: number | null; name?: string | null }) {
  return id ? <span>{name}</span> : <span>-</span>;
}

function ActionType({ type }: { type: string }) {
  const label = AuditEventTypeEnum.labelOf(type);
  return <span>{label}</span>;
}

function Applicant({ event }: { event: AuditEvent }) {
  const action = event.action;
  const id = event.applicantId;
  const tracking = `QM${String(id).padStart(5, "0")}`;
  switch (action.type) {
    case "ApplicantCreated": {
      const name = formatNameWithInitials(action.applicant.details.surname, action.applicant.details.forenames);
      return (
        <span>
          {" "}
          <a href={`/applicant/${id}`} target="_blank" rel="noopener noreferrer">
            {tracking} {name === "-" ? "" : name}
          </a>{" "}
        </span>
      );
    }
    default:
      return (
        <span>
          {" "}
          <a href={`/applicant/${id}`} target="_blank" rel="noopener noreferrer">
            {tracking}
          </a>{" "}
        </span>
      );
  }
}

function Application({ event }: { event: AuditEvent }) {
  const action = event.action;
  switch (action.type) {
    case "ApplicationCreated":
    case "ApplicationUpdated":
    case "ProgrammeClosed": {
      const applicantId = event.applicantId;
      const applicationId = action.application.id;
      const progCode = action.application.programmeCode;
      const status = action.application.offerStatus || action.application.statusLabel;
      return (
        <span>
          <a href={`/applicant/${applicantId}?application=${applicationId}`} target="_blank" rel="noopener noreferrer">
            {progCode} — {status}
          </a>
        </span>
      );
    }

    default:
      return <span>-</span>;
  }
}

function AuditTypeDropdown({ value, onChange }: { value: AuditEventType | undefined; onChange: (value: AuditEventType | undefined) => void }) {
  const eventList = [
    { value: undefined, text: "All Events", key: "" },
    ...AuditEventTypeEnum.entries.map(e => ({ value: e.value, text: e.label, key: e.value })),
  ];

  const asEventType = (value: string): AuditEventType | undefined => AuditEventTypeEnum.entries.find(evt => evt.value === value)?.value;

  return (
    <Dropdown
      options={eventList}
      value={value}
      onChange={(_uiEvent, data) => onChange(asEventType(data.value as string))}
      search
      selection
      fluid
      clearable
      placeholder="Select an event"
    />
  );
}

function AuditPage() {
  return (
    <Title title="Audit Log">
      <Container>
        <AuditList />
      </Container>
    </Title>
  );
}

export default AuditPage;

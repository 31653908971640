import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Grid, Icon, Segment } from "semantic-ui-react";
import { Permissions } from "../model/permission";
import { Team } from "../model/team";
import { ApplicantEditorDTO, Label, Note, User } from "../model/types";
import { Opt } from "../utils";
import ApplicantAttachments from "./ApplicantAttachments";
import ApplicantAuditTrail from "./ApplicantAuditTrail";
import ApplicantLabels from "./ApplicantLabels";
import ApplicantNotes from "./ApplicantNotes";
import { ApplicantOtherViewers } from "./ApplicantOtherViewers";
import TrackingNumberView from "./TrackingNumberView";
import UcasLink from "./UcasLink";
import ClickToCall from "./ClickToCall";

interface Props {
  dto: ApplicantEditorDTO;
  permissions: Permissions;
  currentTeam: Team;
  allTeams: Team[];
  user: Opt<User>;
  onUpdateLabel: (oldLabel: Label, newLabel: Label) => void;
  onAddNote: () => void;
  onSaveNote?: (oldNote: Note, newNote: Note) => void;
  onUpdateNote: (oldNote: Note, newNote: Note) => void;
  onDeleteNote: (note: Note) => void;
}

export default class ApplicantSidebar extends Component<Props> {
  render() {
    const { dto, permissions, currentTeam, allTeams, onUpdateLabel, onAddNote, onSaveNote, onUpdateNote, onDeleteNote, user } = this.props;

    const {
      applicant: {
        id: applicantId,
        details: { email },
      },
      labels,
      notes,
      applications,
    } = dto;

    const hasApplicant = applicantId >= 0;

    return (
      <Segment secondary style={{ border: "none", boxShadow: "none" }}>
        <Segment basic vertical>
          <Grid>
            <Grid.Row>
              <Grid.Column mobile="16" tablet="8" computer="8" textAlign="center">
                <UcasLink value={dto.applicant.details.ucasPersonalId} />
              </Grid.Column>
              <Grid.Column mobile="16" tablet="8" computer="8" textAlign="center">
                <Link target="_blank" to={`/applicant/${dto.applicant.id}/print`}>
                  <Icon name="print" />
                  Print version
                </Link>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>

        <Segment basic vertical>
          <TrackingNumberView applicantId={applicantId} />
        </Segment>

        {applicantId > -1 ? (
          <Segment basic vertical>
            <ApplicantOtherViewers applicantId={applicantId} />
          </Segment>
        ) : null}

        {hasApplicant ? (
          <Segment basic vertical>
            <ClickToCall applicantId={applicantId} numbers={[dto.applicant.details.telephone1, dto.applicant.details.telephone2]} />
          </Segment>
        ) : null}

        <Segment basic vertical>
          <ApplicantLabels
            applicantId={applicantId}
            labels={labels}
            teamCode={currentTeam.code}
            allTeams={allTeams}
            applications={applications}
            onUpdateLabel={onUpdateLabel}
            permissions={permissions}
          />
        </Segment>

        {hasApplicant && (
          <Segment basic vertical>
            <ApplicantAuditTrail applicantId={applicantId} />
          </Segment>
        )}

        {hasApplicant && user && (
          <Segment basic vertical>
            <ApplicantAttachments applicantId={applicantId} applicantEmail={email} username={user.username} />
          </Segment>
        )}

        <Segment basic vertical>
          <ApplicantNotes
            notes={notes}
            onAddNote={onAddNote}
            onSaveNote={onSaveNote}
            onUpdateNote={onUpdateNote}
            onDeleteNote={onDeleteNote}
            permissions={permissions}
            authorUserId={user?.id}
            teamCode={currentTeam.code}
          />
        </Segment>
      </Segment>
    );
  }
}

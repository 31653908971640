import React from "react";
import { User } from "../model/types.generated";
import { Dropdown } from "semantic-ui-react";

export const UserDropdown = ({ users, value, onChange }: { users: User[]; value: User | undefined; onChange: (user: User | undefined) => void }) => {
  const userOptions = [
    { value: undefined, text: "All Users", key: -1 },
    ...users.map(u => ({ value: u.id, text: `${u.surname}, ${u.forenames}`, key: u.id })),
  ];

  const userOf = (id?: number) => users.find(u => u.id === id);

  return (
    <Dropdown
      onChange={(_event, data) => onChange(userOf(data.value as number))}
      options={userOptions}
      placeholder="Select a user"
      search
      selection
      clearable
      fluid
      value={value?.id}
    />
  );
};

import React, { useState } from "react";
import { Message, Modal, Header, Button, ModalProps } from "semantic-ui-react";
import { Messages, indexedErrors, subErrors } from "../model/errors";
import ErrorDisplay from "./ErrorDisplay";
import { DEFAULT_REQUIREMENTS, Requirements } from "@qmspringboard/shared/dist/model/requirements";
import RequirementsEditor from "@qmspringboard/app/src/pages/ProgrammeEditorPage/RequirementsEditor";

interface Props extends ModalProps {
  onPrimaryClick?: (e: React.MouseEvent<HTMLButtonElement>, req: Requirements) => void;
  messages: Messages;
  onHide: () => void;
}

export default function ModalBulkEditEntryRequirements({ show, onPrimaryClick, messages, onHide }: Props) {
  const [entryRequirements, setEntryRequirements] = useState(DEFAULT_REQUIREMENTS);
  const localMessages: Messages = []; //requirementsRule(entryRequirements);

  const handlePrimaryClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    onPrimaryClick?.(e, entryRequirements);
  };

  // take the errors from the first bulk update for now
  // ideally we would combine errors for all and combine with the programme code.
  // however given the nature of this update, the same errors are likely to occur on *all* records.
  const firstError = indexedErrors(messages, 1);
  const splitErrors = subErrors(firstError[0], {
    entryRequirements: true,
  });

  return (
    <Modal open={show} onClose={onHide} size="small">
      <Header content="Replace Entry Requirements on Selected Programmes" />
      <Message
        warning
        content="These values will replace the settings for the selected programmes. For example, a blank overall requirement will mean all selected programmes will have no overall requirements."
      />

      <ErrorDisplay attached messages={splitErrors._rest_} />
      <Modal.Content>
        <RequirementsEditor
          value={entryRequirements}
          messages={[...localMessages, ...splitErrors.entryRequirements]}
          readOnly={false}
          onChange={setEntryRequirements}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button primary onClick={handlePrimaryClick} disabled={localMessages.length > 0}>
          Replace All
        </Button>
        <Button onClick={onHide}>Cancel</Button>
      </Modal.Actions>
    </Modal>
  );
}

import { Condition } from "@qmspringboard/shared/src/model/requirements.generated";
import { indexedErrors, subErrors } from "@qmspringboard/app/src/model/errors";
import { Button, Grid, Icon, SemanticICONS, Table } from "semantic-ui-react";
import { DropdownField, DropdownOptions } from "@qmspringboard/app/src/components/fields";
import update from "immutability-helper";
import { DEFAULT_REQUIRED_SUBJECT_NO_GRADE } from "@qmspringboard/shared/src/model/requirements";
import ErrorDisplay from "@qmspringboard/app/src/components/ErrorDisplay";
import React from "react";
import { RequiredSubjectEditor } from "@qmspringboard/app/src/pages/ProgrammeEditorPage/RequiredSubjectEditor";
import range from "lodash/range";
import { ItemEditorProps } from "@qmspringboard/app/src/pages/ProgrammeEditorPage/types";

const numberOfSubjectOptions: (max: number) => DropdownOptions<number> = max =>
  range(max).map(i => ({
    value: i + 1,
    label: `Must have ${i + 1} of the following subjects`,
  }));

export const IconButton = ({ icon = "remove", ...passProps }: { icon?: SemanticICONS; disabled?: boolean; onClick?: () => void }) => (
  <Button basic icon {...passProps}>
    <Icon name={icon} />
  </Button>
);

export function ConditionEditor({
  value,
  onRemove,
  messages,
  path,
  onChange,
  ...passProps
}: ItemEditorProps<Condition> & {
  onRemove: () => void;
  path: string;
}) {
  const { numberRequired, subjects } = value;

  const splitErrors = subErrors(messages, {
    conditions: true,
  });

  const conditionSplitErrors = indexedErrors(splitErrors.conditions, subjects.length);

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={16}>
          <Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell style={{ fontWeight: "normal" }}>
                  <DropdownField
                    value={numberRequired}
                    options={numberOfSubjectOptions(subjects.length)}
                    onChange={numberRequired => onChange({ ...value, numberRequired })}
                  />
                </Table.HeaderCell>
                <Table.HeaderCell>A2</Table.HeaderCell>
                <Table.HeaderCell>IB</Table.HeaderCell>
                <Table.HeaderCell textAlign="right">
                  <Button basic icon onClick={onRemove}>
                    <Icon name="delete" />
                  </Button>
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {subjects.map((subject, i) => (
                <Table.Row key={`${path}-${i}`} verticalAlign="top">
                  <RequiredSubjectEditor
                    {...passProps}
                    path={`${path}-${i}`}
                    value={subject}
                    messages={conditionSplitErrors[i]}
                    onChange={v => {
                      onChange(
                        update(value, {
                          subjects: { [i]: { $set: v } },
                        }),
                      );
                    }}
                  />
                  <Table.Cell textAlign="right">
                    <IconButton
                      icon="minus"
                      disabled={i === 0 && subjects.length === 1}
                      onClick={() => {
                        onChange(
                          update(value, {
                            numberRequired: {
                              $set: numberRequired > subjects.length - 1 ? numberRequired - 1 : numberRequired,
                            },
                            subjects: { $splice: [[i, 1]] },
                          }),
                        );
                      }}
                    />
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <Button
            onClick={() => {
              onChange(
                update(value, {
                  subjects: { $push: [DEFAULT_REQUIRED_SUBJECT_NO_GRADE] },
                }),
              );
            }}
          >
            Add Another Subject
          </Button>
          <ErrorDisplay messages={splitErrors._rest_} />
          {/* <Button
                onClick={() => {
                  onChange(
                    update(value, {
                      conditions: { $push: [DEFAULT_REQUIRED_SUBJECT] },
                    }),
                  );
                }}
              >
                Add a Group of Subjects
              </Button> */}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}

import { Message } from "@qmspringboard/shared/dist/model/core.generated";
import { Requirements } from "@qmspringboard/shared/dist/model/requirements";
import React from "react";
import { Grid } from "semantic-ui-react";
import { subErrors } from "../../model/errors";
import { EntryRequirementsExtra } from "@qmspringboard/app/src/pages/ProgrammeEditorPage/EntryRequirementsExtra";
import { RequirementsAlternativesEditor } from "@qmspringboard/app/src/pages/ProgrammeEditorPage/RequirementsAlternativeEditor";

export interface RequirementsEditorProps {
  readOnly: boolean;
  value: Requirements;
  messages: Message[];
  onChange: (newValue: Requirements) => void;
}

export default function RequirementsEditor({ value, readOnly, messages, onChange }: RequirementsEditorProps) {
  const splitErrors = subErrors(messages, {
    alternatives: true,
  });

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={16}>
          <RequirementsAlternativesEditor
            path="gradeRequirements"
            messages={splitErrors.alternatives}
            readOnly={readOnly}
            alternatives={value.alternatives}
            contextualAlternatives={value.contextualAlternatives}
            onChange={(alternatives, contextualAlternatives) => onChange({ ...value, alternatives, contextualAlternatives })}
          />
        </Grid.Column>
      </Grid.Row>
      <EntryRequirementsExtra path="extra" messages={splitErrors._rest_} readOnly={readOnly} value={value} onChange={onChange} />
    </Grid>
  );
}

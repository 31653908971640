import { Requirements } from "@qmspringboard/shared/src/model/requirements.generated";
import { subErrors } from "@qmspringboard/app/src/model/errors";
import { Form, Grid } from "semantic-ui-react";
import { BooleanRadioField, FieldLabel, NullableTextArea } from "@qmspringboard/app/src/components/fields";
import { EnglishRequirementsEditor } from "@qmspringboard/app/src/pages/ProgrammeEditorPage/EnglishRequirementsEditor";
import ErrorDisplay from "@qmspringboard/app/src/components/ErrorDisplay";
import React from "react";
import { ItemEditorProps } from "@qmspringboard/app/src/pages/ProgrammeEditorPage/types";

export function EntryRequirementsExtra({ value, readOnly, messages, onChange }: ItemEditorProps<Requirements>) {
  const splitErrors = subErrors(messages, {
    english: true,
    maths: true,
  });

  return (
    <>
      <Grid.Row>
        <Grid.Column width={16}>
          <FieldLabel label="English language requirements">
            <EnglishRequirementsEditor value={value.english} onChange={english => onChange({ ...value, english })} />
            <ErrorDisplay attached messages={splitErrors.english} />
          </FieldLabel>
        </Grid.Column>
        <Grid.Column width={16}>
          <FieldLabel label="Mathematics requirements">
            <BooleanRadioField
              value={value.maths}
              readOnly={readOnly}
              onChange={maths => onChange({ ...value, maths })}
              trueLabel="GCSE Maths grade B/5 or equivalent"
              falseLabel="No requirement"
            />
            <ErrorDisplay attached messages={splitErrors.maths} />
          </FieldLabel>
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column width={16}>
          <Form as="div">
            <FieldLabel label="Internal Notes">
              <NullableTextArea
                rows={10}
                value={value.internalNotes}
                disabled={readOnly}
                onChange={internalNotes => onChange({ ...value, internalNotes: internalNotes ?? null })}
              />
              <p>These notes are for office use and don&apos;t appear in other Springboard web pages or emails.</p>
            </FieldLabel>
          </Form>
          <ErrorDisplay messages={splitErrors._rest_} />
        </Grid.Column>

        <Grid.Column width={16}>
          <Form as="div">
            <FieldLabel label="Guidance Notes">
              <NullableTextArea
                rows={5}
                value={value.guidanceNotes}
                disabled={readOnly}
                onChange={guidanceNotes => onChange({ ...value, guidanceNotes: guidanceNotes ?? null })}
              />
              <p>These appear next to the on-screen requirements when reporting on an applicant&apos;s eligibility.</p>
            </FieldLabel>
          </Form>
          <ErrorDisplay messages={splitErrors._rest_} />
        </Grid.Column>
      </Grid.Row>
    </>
  );
}

import useInterval from "react-useinterval";
import { Container, Message } from "semantic-ui-react";
import * as React from "react";

interface BatchInProgressProps {
  onInterval: () => void;
}

export const BatchInProgress = (props: BatchInProgressProps) => {
  useInterval(props.onInterval, 2000);
  return (
    <Container>
      <Message
        color="yellow"
        icon="wait"
        size="small"
        header="Import In Progress"
        content="A Selector List import is currently in progress. This page will automatically refresh when the import completes, but you can navigate away without interrupting the import."
      />
    </Container>
  );
};

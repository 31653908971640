import { Message, Segment, Table, Grid, Container, Divider, Dimmer, Loader, Button, ButtonGroup } from "semantic-ui-react";
import Papa, { ParseResult } from "papaparse";
import { Role, UserRole, SchoolCode, isGlobalPermissionType } from "../model/types";
import { FieldLabel } from "../components/fields";
import { User } from "../model/types";
import { RouteComponentProps } from "react-router";
import Title from "../components/Title";
import SubmitButton from "../components/SubmitButton";
import { userRule } from "../model/user";
import { Messages, indexedErrors, subErrors } from "../model/errors";
import { Message as ErrMessage } from "../model/types";
import React from "react";
import { ValidationError } from "../errors";
import ErrorDisplay from "../components/ErrorDisplay";
import FileUploadZone from "../components/FileUploadZone";
import { Stringified } from "type-fest";
import useAllRoles from "../hooks/useAllRoles";
import useAllSchools from "../hooks/useAllSchools";
import { useAddUsers, PartialSuccess } from "../api";

const subErrorKeys = {
  username: true,
  email: true,
  first_name: true,
  last_name: true,
  home_school: true,
  additional_schools: true,
};

// CheckedData is result of  minimal CSV parsing, turning rows into strings
type CheckedData = Stringified<typeof subErrorKeys>;

const requiredFields = Object.keys(subErrorKeys) as Array<keyof typeof subErrorKeys>;

// Next-level of parsed data, with types we need resolved
interface ParsedData {
  username: string;
  email: string;
  first_name: string;
  last_name: string;
  home_school: SchoolCode | null;
  additional_schools: SchoolCode[];
}

const splitSchoolList = (schools: string): string[] => schools.split(/[ ,]+/);

const toParseData =
  (allSchoolCodes: SchoolCode[]) =>
  (row: CheckedData): ParsedData => {
    const asSchoolCode = (school: string): SchoolCode | null => allSchoolCodes.find(code => code === school) || null;

    const asSchoolCodes = (schools: string): SchoolCode[] =>
      splitSchoolList(schools)
        .map(asSchoolCode)
        .flatMap(c => (c ? [c] : []));

    return {
      username: row.username,
      first_name: row.first_name,
      last_name: row.last_name,
      email: row.email,
      home_school: row.home_school ? asSchoolCode(row.home_school) : null,
      additional_schools: row.additional_schools ? asSchoolCodes(row.additional_schools) : [],
    };
  };

const MAX_ROWS_TO_SHOW = 10;

const validate = (users: User[]): Messages => {
  const messages: Messages = [];
  for (const [i, user] of users.entries()) {
    messages.push(...userRule(user).map(m => ({ ...m, path: [i, ...m.path] })));
  }

  return messages;
};

const csvParseError = (msg: string): ErrMessage => ({
  level: "error",
  path: [],
  text: msg,
});

const validateRows =
  (allSchoolCodes: SchoolCode[]) =>
  (results: ParseResult<CheckedData>): Messages => {
    // All fields are mandarory
    const allFieldsPresent = requiredFields.every(f => results.meta.fields?.includes(f) ?? false);
    if (!allFieldsPresent) {
      return [csvParseError(`CSV must contain a header with the following fields ${requiredFields.join(",")}`)];
    }

    // Find any unrecognized school codes:
    const badSchool = (school: string): boolean => !!school && !allSchoolCodes.includes(school as SchoolCode);

    const unrecognizedHomeSchools: Messages = results.data
      .map(row => row.home_school)
      .filter(badSchool)
      .map(school => csvParseError(`Unrecognised home school code: ${school}`));

    const unrecognizedAddSchools: Messages = results.data
      .map(row => row.additional_schools)
      .flatMap(splitSchoolList)
      .filter(badSchool)
      .map(school => csvParseError(`Unreconised additional school code: ${school}`));

    return [...unrecognizedHomeSchools, ...unrecognizedAddSchools]; // Could be [] i.e., no errors
  };

function BulkUserEditorPage({ history }: RouteComponentProps) {
  const allRoles = useAllRoles();
  const allSchools = useAllSchools();
  const allSchoolCodes = React.useMemo(() => allSchools.map(s => s.code), [allSchools]);

  const {
    mutate: saveUsers,
    status,
    error: serverMessages,
    reset,
  } = useAddUsers({
    onSuccess: () => {
      // go to user list
      history.push("/admin/user");
    },
  });

  const [errors, setErrors] = React.useState<Messages>([]);
  const [showRows, setShowRows] = React.useState(MAX_ROWS_TO_SHOW);
  const [result, setResult] = React.useState<ParsedData[]>([]);
  const [roles, setRoles] = React.useState<Role[]>([]);

  const onDrop = React.useCallback(
    (acceptedFiles: File[]) => {
      if (acceptedFiles[0]) {
        const file = acceptedFiles[0];
        Papa.parse<CheckedData>(file, {
          header: true,
          skipEmptyLines: "greedy", // greedy means skip rows that are blank or just whitespace
          transform: function (value) {
            return value.trim();
          },
          complete: function (results) {
            const errors = validateRows(allSchoolCodes)(results);
            errors.length > 0 ? setErrors(errors) : setResult(results.data.map(toParseData(allSchoolCodes)));
          },
        });
      }
    },
    [allSchoolCodes],
  );

  React.useEffect(() => {
    // reset any server errors
    reset();
  }, [result, roles, reset]);

  const users = React.useMemo(() => {
    // For a given user, a role might be applicable to one or more schools, all schools (for admmissions),
    // or none (if the role has no school-specific permissions).
    const schoolsForUserRole = (homeSchool: SchoolCode | null, additionalSchools: SchoolCode[], role: Role): SchoolCode[] => {
      const onlyGlobalPermissions = role.permissions.every(isGlobalPermissionType);
      return onlyGlobalPermissions ? [] : homeSchool ? [homeSchool, ...additionalSchools] : allSchoolCodes;
    };

    // combine all our values into a 'User' type
    const users: User[] = result.map(u => {
      // Take the selected Roles and turn them into UserRoles by adding schools
      const userRoles: UserRole[] = roles.map(r => ({
        role: r,
        schools: schoolsForUserRole(u.home_school, u.additional_schools, r),
      }));

      return {
        id: -1,
        email: u.email,
        forenames: u.first_name,
        surname: u.last_name,
        username: u.username,
        roles: userRoles,
        canLogin: true,
        defaultSchoolCode: u.home_school,
      };
    });
    return users;
  }, [result, roles, allSchoolCodes]);

  const messages = serverMessages instanceof PartialSuccess ? serverMessages.data : validate(users);

  const visibleErrors = indexedErrors(messages, Number.isFinite(showRows) ? showRows : result.length);

  return (
    <Title title="Bulk Add Users">
      <Dimmer.Dimmable as={Container}>
        <Dimmer active={status === "loading"} inverted>
          <Loader>Saving</Loader>
        </Dimmer>

        {status === "error" && serverMessages instanceof ValidationError && <ErrorDisplay messages={serverMessages.messages} />}

        {status === "error" && serverMessages instanceof PartialSuccess && (
          <Message negative>
            <Message.Header>Some users couldn&apos;t be uploaded</Message.Header>
            <p>Users outlined in red below could not be uploaded. Other users were successfully added.</p>
          </Message>
        )}

        <Grid>
          <Grid.Row>
            {result.length > 0 ? (
              <Grid.Column width={16}>
                <Table celled>
                  <Table.Header>
                    <Table.Row>
                      {requiredFields.map(f => (
                        <Table.HeaderCell key={f}>{f}</Table.HeaderCell>
                      ))}
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {result.slice(0, showRows).map((row, i) => {
                      const rowErrors = subErrors(visibleErrors[i], subErrorKeys);
                      const isErrorRow = visibleErrors[i].length > 0;
                      return (
                        <Table.Row key={i}>
                          {requiredFields.map(f => {
                            let thisFieldIsError = rowErrors[f] && rowErrors[f].length > 0;
                            return (
                              <Table.Cell error={isErrorRow} key={`${i}.${f}`}>
                                {thisFieldIsError ? (
                                  <span>
                                    {row[f]} - {rowErrors[f][0].text}
                                  </span>
                                ) : Array.isArray(row[f]) ? (
                                  (row[f] as SchoolCode[]).join(", ")
                                ) : (
                                  row[f]
                                )}
                              </Table.Cell>
                            );
                          })}
                        </Table.Row>
                      );
                    })}
                  </Table.Body>
                </Table>
                <div>
                  {result.length > showRows ? (
                    <Button onClick={() => setShowRows(Infinity)}>Show {result.length - showRows} more row(s)</Button>
                  ) : null}
                  <Button
                    onClick={() => {
                      setErrors([]);
                      setResult([]);
                    }}
                  >
                    Clear Upload
                  </Button>
                </div>
              </Grid.Column>
            ) : (
              <Grid.Column width={16}>
                <FileUploadZone messages={errors} onDrop={onDrop} onClear={() => setResult([])} requiredFields={requiredFields} />
              </Grid.Column>
            )}
          </Grid.Row>

          <FieldLabel
            label={
              <span>
                <strong>Roles</strong> — Select the roles for this batch of users
              </span>
            }
          ></FieldLabel>
          <Grid.Row>
            {allRoles.map((role, index) => (
              <Grid.Column key={index} width={8}>
                <ButtonGroup fluid basic toggle sx={{ flexWrap: "wrap" }}>
                  <Button
                    active={roles.includes(role)}
                    onClick={() => (roles.includes(role) ? setRoles(roles.filter(r => r !== role)) : setRoles([...roles, role]))}
                  >
                    {role.name}
                  </Button>
                </ButtonGroup>
              </Grid.Column>
            ))}
          </Grid.Row>
        </Grid>

        <Divider hidden />

        <Segment basic vertical textAlign="right">
          <SubmitButton disabled={result.length === 0} onClick={() => saveUsers(users)} messages={messages}>
            Add Users
          </SubmitButton>
        </Segment>
      </Dimmer.Dimmable>
    </Title>
  );
}

export default BulkUserEditorPage;

export function actionName(actionType: string) {
  switch (actionType) {
    case "ImportApplication":
      return "Import Application";

    case "NationalityChange":
      return "Nationality Change";

    case "NameChange":
      return "Name Change";

    case "FeeStatusChange":
      return "Fee Status Change";

    default:
      return actionType;
  }
}

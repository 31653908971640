import { RequiredSubject } from "@qmspringboard/shared/src/model/requirements.generated";
import { DEFAULT_A2GRADE, DEFAULT_IBGRADE } from "@qmspringboard/shared/src/model/requirements";
import { Button, Table } from "semantic-ui-react";
import SubjectDropdown from "@qmspringboard/app/src/components/SubjectDropdown";
import ErrorDisplay from "@qmspringboard/app/src/components/ErrorDisplay";
import { subErrors } from "@qmspringboard/app/src/model/errors";
import { ALevelGradeEditor, IBGradeEditor } from "@qmspringboard/app/src/pages/ProgrammeEditorPage/GradeEditor";
import { assertNever } from "@qmspringboard/app/src/utils";
import React from "react";
import { ItemEditorProps } from "@qmspringboard/app/src/pages/ProgrammeEditorPage/types";

export function RequiredSubjectEditor({ value, messages, onChange, ...passProps }: ItemEditorProps<RequiredSubject>) {
  switch (value.type) {
    case "RequiredSubjectNoGrade": {
      const handleAddGrade = () => {
        onChange({
          type: "RequiredSubjectAtGrade",
          a2Grade: DEFAULT_A2GRADE,
          ibGrade: DEFAULT_IBGRADE,
          subject: value.subject,
        });
      };
      return (
        <>
          <Table.Cell>
            <SubjectDropdown
              qualification={"Other"}
              value={value.subject}
              onChange={name =>
                onChange({
                  ...value,
                  subject: name ?? "",
                })
              }
            />
            <ErrorDisplay attached messages={messages} />
          </Table.Cell>
          <Table.Cell colSpan={2}>
            <Button fluid size="medium" onClick={handleAddGrade}>
              Add Min Grades
            </Button>
          </Table.Cell>
        </>
      );
    }
    case "RequiredSubjectAtGrade": {
      // downgrade us to RequiredSubject
      const handleRemove = () => {
        onChange({
          type: "RequiredSubjectNoGrade",
          subject: value.subject,
        });
      };
      const splitErrors = subErrors(messages, {
        grade: true,
      });
      return (
        <>
          <Table.Cell>
            <SubjectDropdown
              qualification="Other"
              value={value.subject}
              onChange={name =>
                onChange({
                  ...value,
                  subject: name ?? "",
                })
              }
            />
            <ErrorDisplay messages={splitErrors._rest_} />
          </Table.Cell>
          <Table.Cell>
            <ALevelGradeEditor
              {...passProps}
              onRemove={handleRemove}
              messages={splitErrors.grade}
              value={value.a2Grade}
              onChange={a2Grade => onChange({ ...value, a2Grade })}
            />
          </Table.Cell>
          <Table.Cell>
            <IBGradeEditor
              {...passProps}
              onRemove={handleRemove}
              messages={splitErrors.grade}
              value={value.ibGrade}
              onChange={ibGrade => onChange({ ...value, ibGrade })}
            />
          </Table.Cell>
        </>
      );
    }
    default: {
      assertNever(value);
    }
  }
}

import { EnglishRequirement } from "@qmspringboard/shared/dist/model/requirements.generated";

type Band = { label: string; template: EnglishRequirement };

export const bands: Band[] = [
  {
    label: "STA",
    template: {
      gcse: "C",
      ielts: {
        overall: 7,
        listening: 5.5,
        reading: 5.5,
        writing: 7,
        speaking: 5.5,
      },
      frenchBac: 14,
      indianHs: 80,
      toefl: {
        overall: 100,
        listening: 17,
        reading: 18,
        writing: 27,
        speaking: 20,
      },
    },
  },
  {
    label: "History, Geo, FMD, SPIR and Law",
    template: {
      gcse: "C",
      ielts: {
        overall: 7,
        listening: 5.5,
        reading: 5.5,
        writing: 6.5,
        speaking: 5.5,
      },
      frenchBac: 14,
      indianHs: 80,
      toefl: {
        overall: 100,
        listening: 17,
        reading: 18,
        writing: 24,
        speaking: 20,
      },
    },
  },
  {
    label: "SEF, SBBS, Environ Science",
    template: {
      gcse: "C",
      ielts: {
        overall: 6.5,
        listening: 5.5,
        reading: 5.5,
        writing: 6,
        speaking: 5.5,
      },
      frenchBac: 14,
      indianHs: 70,
      toefl: {
        overall: 92,
        listening: 17,
        reading: 18,
        writing: 21,
        speaking: 20,
      },
    },
  },
  {
    label: "Maths, EECS, SEMS and Physics",
    template: {
      gcse: "C",
      ielts: {
        overall: 6,
        listening: 5.5,
        reading: 5.5,
        writing: 5.5,
        speaking: 5.5,
      },
      frenchBac: 13,
      indianHs: 60,
      toefl: {
        overall: 79,
        listening: 17,
        reading: 18,
        writing: 17,
        speaking: 20,
      },
    },
  },
];

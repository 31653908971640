import React from "react";
import { OverallRequirement } from "@qmspringboard/shared/src/model/requirements.generated";
import { subErrors } from "@qmspringboard/app/src/model/errors";
import { Button, Table } from "semantic-ui-react";
import { ALevelGradesEditor, BtecGradesEditor, IBGradesEditor } from "@qmspringboard/app/src/pages/ProgrammeEditorPage/GradeEditor";
import { DropdownField, DropdownOptions, NullableNumberInput } from "@qmspringboard/app/src/components/fields";
import ErrorDisplay from "@qmspringboard/app/src/components/ErrorDisplay";
import { ALevelEquivalence, ALevelEquivalenceEnum } from "@qmspringboard/shared/src/model/enums.generated";
import { ItemEditorProps } from "@qmspringboard/app/src/pages/ProgrammeEditorPage/types";

const equivalenceOptions: DropdownOptions<ALevelEquivalence> = [
  {
    key: "Strict",
    value: ALevelEquivalenceEnum.Strict,
    label: "Strict",
    content: (
      <div>
        <h4>Strict Equivalence</h4>
        <p>Strict equivalence for overall A2 checks (e.g. BBB grades would not meet ABC requirements)</p>
      </div>
    ),
  },
  {
    key: "Rough",
    value: ALevelEquivalenceEnum.Rough,
    label: "Rough",
    content: (
      <div>
        <h4>Rough Equivalence</h4>
        <p>Rough Rquivalence for overall A2 checks (e.g. BBB grades would meet ABC requirements)</p>
      </div>
    ),
  },
];

export function OverallRequirementEditor({ value, onChange, messages, ...passProps }: ItemEditorProps<OverallRequirement>) {
  const splitErrors = subErrors(messages, {
    a2Grades: true,
    btecGrades: true,
    ibGrades: true,
  });

  return (
    <Table verticalAlign="top">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell width={4}></Table.HeaderCell>
          <Table.HeaderCell width={2}>A2s</Table.HeaderCell>
          <Table.HeaderCell width={2}>IB Higher</Table.HeaderCell>
          <Table.HeaderCell width={2}>IB Total</Table.HeaderCell>
          <Table.HeaderCell width={2}>BTEC</Table.HeaderCell>
          <Table.HeaderCell width={2}>Equivalence</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        <Table.Row verticalAlign="top">
          <Table.Cell width={4}>Minimum Grades</Table.Cell>
          <Table.Cell width={2}>
            <ALevelGradesEditor
              {...passProps}
              value={value.a2Grades}
              messages={splitErrors.a2Grades}
              onChange={a2Grades => onChange({ ...value, a2Grades })}
            />
          </Table.Cell>
          <Table.Cell width={2}>
            <IBGradesEditor
              {...passProps}
              value={value.ibGrades}
              messages={splitErrors.ibGrades}
              onChange={ibGrades => onChange({ ...value, ibGrades })}
            />
          </Table.Cell>
          <Table.Cell width={2}>
            <NullableNumberInput
              fluid
              value={value.ibPoints}
              placeholder="eg 32"
              onChange={ibPoints => {
                onChange({ ...value, ibPoints: ibPoints ?? 0 });
              }}
            />
          </Table.Cell>
          <Table.Cell width={2}>
            {value.btecGrades === null ? (
              <Button fluid size="medium" onClick={() => onChange({ ...value, btecGrades: [] })}>
                Add
              </Button>
            ) : null}
            {value.btecGrades ? (
              <BtecGradesEditor
                {...passProps}
                value={value.btecGrades}
                messages={splitErrors.btecGrades}
                onRemove={() => onChange({ ...value, btecGrades: null })}
                onChange={btecGrades => onChange({ ...value, btecGrades })}
              />
            ) : null}
          </Table.Cell>
          <Table.Cell width={4}>
            <DropdownField
              fluid
              value={value.aLevelEquivalence}
              options={equivalenceOptions}
              onChange={aLevelEquivalence => {
                onChange({ ...value, aLevelEquivalence });
              }}
            />
            <ErrorDisplay messages={splitErrors._rest_} />
          </Table.Cell>
        </Table.Row>
      </Table.Body>
    </Table>
  );
}

import { guessFeeCategory as guessFeeCategory } from "@qmspringboard/shared/src/model/feeCategory";
import React, { useState } from "react";
import { Button, Container, Dimmer, Divider, Dropdown, DropdownItemProps, Grid, Segment } from "semantic-ui-react";
import ApplicantDetailsEditor from "../../components/ApplicantDetailsEditor";
import ApplicantSidebar from "../../components/ApplicantSidebar";
import ApplicationsEditor from "../../components/ApplicationsEditor";
import ErrorDisplay from "../../components/ErrorDisplay";
import FeeCategoryEditor from "../../components/FeeCategoryEditor";
import QualificationsEditor from "../../components/QualificationsEditor";
import SubmitButton from "../../components/SubmitButton";
import Title from "../../components/Title";
import { RequirementsChecksProvider } from "../../hooks/useRequirementsChecks";
import { ApplicantDetails, ApplicantEditorState } from "../../model/applicant";
import { Messages, subErrors } from "../../model/errors";
import { Permissions } from "../../model/permission";
import { Team, TeamCode, teamToSchoolCode } from "../../model/team";
import { ApplicantDTO, ApplicantEditorDTO, ApplicantId, Application, ApplicationChoice, Label, Note, User } from "../../model/types";
import { Opt, replace } from "../../utils";
import { mandatoryDoBEditorStateRule } from "@qmspringboard/app/src/model/applicantRules";
import { isEqual, sortBy, without } from "lodash";
import { blankApplication } from "@qmspringboard/app/src/api";
import { Prompt } from "react-router";
import * as api from "../../api";
import * as toast from "@qmspringboard/app/src/components/toast";
import { ModalApplicationScript } from "@qmspringboard/app/src/pages/ApplicantCreatePage/ModalApplicationScript";
import { SaveModal } from "@qmspringboard/app/src/pages/ApplicantCreatePage/SaveModal";
import { willHaveScript } from "@qmspringboard/app/src/pages/ApplicantCreatePage/helpers";
import { AppError } from "../../errors";
import { ModalErrorDialog } from "../../components/ModalErrorDialog";

type ApplicantCreateState =
  | { state: "EDITING" }
  | { state: "SAVING" }
  | { state: "SHOW_SCRIPTS"; id: ApplicantId; applications: Application[] }
  | { state: "SHOW_ERROR_DIALOG"; error: AppError }
  | { state: "SAVED"; id: ApplicantId };

interface Props {
  currentTeam: Team;
  allTeams: Team[];
  currentUser: Opt<User>;
  permissions: Permissions;
  blankApplicant: ApplicantEditorDTO;
  push: (url: string) => void;
}

export function ApplicantCreatePage(props: Props) {
  const { blankApplicant, permissions, currentTeam, allTeams, currentUser, push } = props;

  const [editorState, setEditorState] = useState<ApplicantCreateState>({
    state: "EDITING",
  });

  const [dto, setDto] = useState<ApplicantEditorDTO>(blankApplicant);

  const hasUnsavedChanges = editorState.state === "EDITING" && !isEqual(dto, blankApplicant);

  const [duplicateUcas, setDuplicateUcas] = useState<Array<ApplicantDTO>>([]);
  const [duplicateStudentId, setDuplicateStudentId] = useState<Array<ApplicantDTO>>([]);
  const [duplicateEmail, setDuplicateEmail] = useState<Array<ApplicantDTO>>([]);

  const [serverMessages, setServerMessages] = useState<Messages>([]);
  const [selectedApplicationIndex, setSelectedApplicationIndex] = useState<number | null>(null);

  const selectedApplication: Application | null = selectedApplicationIndex === null ? null : dto.applications[selectedApplicationIndex];

  const setSelectedApplication = (application: Application) => {
    const index = dto.applications.indexOf(application);
    setSelectedApplicationIndex(index >= 0 ? index : null);
  };

  const applicantEditorState: ApplicantEditorState = {
    ...dto,
    duplicates: {
      ucasPersonalId: duplicateUcas,
      studentId: duplicateStudentId,
      email: duplicateEmail,
    },
  };

  const clientMessages: Messages = mandatoryDoBEditorStateRule(applicantEditorState);
  const messages = clientMessages.concat(serverMessages);

  const qualifications = dto.applicant.qualifications;

  const handleAddApplication = (choice: ApplicationChoice) => {
    blankApplication(choice).then(application => {
      const updatedApplications = [...dto.applications, application];
      setDto({
        ...dto,
        applications: updatedApplications,
      });
      setSelectedApplicationIndex(updatedApplications.length - 1);
    });
  };

  const handleUpdateApplication = (newApplication: Application) => {
    if (selectedApplication) {
      setDto({
        ...dto,
        applications: replace(dto.applications, selectedApplication, newApplication),
      });
    }
  };

  const handleRemoveApplication = () => {
    if (selectedApplication) {
      setDto({
        ...dto,
        applications: without(dto.applications, selectedApplication),
      });
      // TODO: Perhaps select one of the other applications if there are any?
      setSelectedApplicationIndex(null);
    }
  };

  const handleAddNote = () => {
    if (currentUser) {
      const note: Note = {
        applicantId: dto.applicant.id,
        authorUserId: currentUser.id,
        authorName: `${currentUser.forenames} ${currentUser.surname}`,
        authorSchoolCode: currentUser.defaultSchoolCode,
        schoolCode: teamToSchoolCode(currentTeam.code),
        text: "",
        timestamp: new Date().toISOString(),
        id: -1,
      };
      const updatedNotes = dto.notes.concat([note]);
      setDto({ ...dto, notes: updatedNotes });
    } else {
      console.error("Attempt to add note without currentUser");
    }
  };

  const handleUpdateNote = (oldNote: Note, newNote: Note) => {
    const updatedNotes = replace(dto.notes, oldNote, newNote);
    setDto({ ...dto, notes: updatedNotes });
  };

  const handleDeleteNote = (note: Note) => {
    const updatedNotes = without(dto.notes, note);
    setDto({ ...dto, notes: updatedNotes });
  };

  const handleUpdateLabel = (oldLabel: Label, newLabel: Label) => {
    const updatedLabels = sortBy(replace(dto.labels, oldLabel, newLabel), label =>
      `${label.schoolCode}-${label.labelType}-${label.text}`.toLowerCase(),
    );

    setDto({ ...dto, labels: updatedLabels });
  };

  const doSave = (teamCode: TeamCode, dto: ApplicantEditorDTO, email: boolean) => {
    const schoolCode = teamToSchoolCode(teamCode) || undefined;

    const handleError = (error: unknown) => {
      if (error instanceof AppError) {
        setEditorState({ state: "SHOW_ERROR_DIALOG", error });
      } else {
        console.error("Error creating applicant", error);
      }
    };

    api
      .createApplicant(dto, email, schoolCode)
      .then(result => {
        // The server may returning warnings (example: email associated with another applicant).
        // We only show errors, because the record would have been created if there are only warnings.
        if (result.messages && result.messages.some(msg => msg.level === "error")) {
          setServerMessages(result.messages);
        } else {
          const {
            applicant: { id },
            applications,
          } = result.data || dto;

          if (email) {
            toast.success("Applicant created. Email sent.");
          } else {
            toast.success("Applicant created. Email not sent.");
          }

          setEditorState({ state: "SHOW_SCRIPTS", id, applications });
        }
      })
      .catch(handleError);
  };

  function duplicateUcasCheck(ucasPersonalId: string) {
    api.searchApplicants({ q: `ucas:${ucasPersonalId}`, count: 5 }).then(results => setDuplicateUcas(results.items));
  }

  function duplicateStudentIdCheck(studentId: string) {
    api.searchApplicants({ q: `studentid:${studentId}`, count: 5 }).then(results => setDuplicateStudentId(results.items));
  }

  function duplicateEmailCheck(email: string) {
    api.searchApplicants({ q: `email:${email}`, count: 5 }).then(results => setDuplicateEmail(results.items));
  }

  function checkForDuplicates(details: ApplicantDetails) {
    const newUcas = details.ucasPersonalId;
    const oldUcas = dto.applicant.details.ucasPersonalId;
    const newStudentId = details.studentId;
    const oldStudentId = dto.applicant.details.studentId;
    const newEmail = details.email;
    const oldEmail = dto.applicant.details.email;

    if (newUcas && newUcas.length >= 10 && newUcas !== oldUcas) {
      duplicateUcasCheck(newUcas);
    }

    if (newStudentId && newStudentId.length >= 9 && newStudentId !== oldStudentId) {
      duplicateStudentIdCheck(newStudentId);
    }

    if (newEmail && newEmail.length > 8 && newEmail !== oldEmail) {
      duplicateEmailCheck(newEmail);
    }
  }

  const splitErrors = subErrors(messages, {
    applicant: true,
    // transfers: true,
    applications: true,
    _email_: true,
  });

  const detailsErrors = subErrors(splitErrors.applicant, {
    details: true,
    qualifications: true,
  });

  const hasOffer = dto.applications.length > 0;

  function isReferToSchoolLabel(label: Label): boolean {
    if (label.schoolCode) {
      return label.text === "Refer to " + label.schoolCode;
    } else return false;
  }

  const handleSaveAndTransfer = (teamCode: TeamCode, label: Label) => {
    const selectedLabel = { ...label, selected: true };
    const updatedLabels = dto.labels.includes(label) ? replace(dto.labels, label, selectedLabel) : [...dto.labels, selectedLabel];
    const sortedLabels = sortBy(updatedLabels, label => `${label.schoolCode}-${label.labelType}-${label.text}`.toLowerCase());
    const dtoWithLabel: ApplicantEditorDTO = {
      ...dto,
      labels: sortedLabels,
    };

    doSave(teamCode, dtoWithLabel, false);
  };

  // Should we disable the menu items in the "Save and Transfer" dropdown?
  const disableTransferOptions = messages.some(e => e.level === "error");

  // Menu options for the "Save and Transfer" dropdown:
  const transferOptions: DropdownItemProps[] = dto.labels
    .filter(label => label.labelType === "fao" && (label.schoolCode === teamToSchoolCode(currentTeam.code) || isReferToSchoolLabel(label)))
    .map(label => ({
      value: label.prototypeId,
      text: label.text,
      label: {
        empty: true,
        circular: true,
        style: { backgroundColor: label.color },
      },
      onClick: () => !disableTransferOptions && handleSaveAndTransfer(currentTeam.code, label),
      disabled: disableTransferOptions,
    }));

  const guessedFeeCategory = guessFeeCategory(
    dto.applicant.details.feeStatus,
    dto.applicant.details.ukResidency,
    dto.applicant.details.ukImmigrationStatus,
  );

  return (
    <Title title="New Applicant">
      <Container>
        <Dimmer.Dimmable as="div">
          <RequirementsChecksProvider qualifications={dto.applicant.qualifications} ucasPersonalId={dto.applicant.details.ucasPersonalId}>
            <Prompt when={hasUnsavedChanges} message={"You have unsaved changes. Are you sure you want to leave this page?"} />

            {editorState.state === "SHOW_ERROR_DIALOG" && (
              <ModalErrorDialog error={editorState.error} onOk={() => setEditorState({ state: "EDITING" })} />
            )}

            <Grid>
              <Grid.Row>
                <Grid.Column width={12}>
                  <Segment style={{ marginTop: 0 }}>
                    <QualificationsEditor
                      value={dto.applicant.qualifications}
                      messages={detailsErrors.qualifications}
                      readOnly={false}
                      onChange={qualifications =>
                        setDto({
                          ...dto,
                          applicant: {
                            ...dto.applicant,
                            qualifications,
                          },
                        })
                      }
                    />
                  </Segment>

                  <Segment>
                    <FeeCategoryEditor
                      messages={detailsErrors.details}
                      value={dto.applicant.details}
                      readOnly={false}
                      onChange={details =>
                        setDto({
                          ...dto,
                          applicant: {
                            ...dto.applicant,
                            details,
                          },
                        })
                      }
                    />
                  </Segment>

                  <Divider hidden />

                  <Segment>
                    <ApplicationsEditor
                      // TODO: Check these next four props:
                      allTeams={allTeams}
                      anythingEditing={false}
                      applicantSaved={false}
                      editingApplication={null}
                      //
                      readOnly={false}
                      currentTeam={currentTeam}
                      permissions={permissions}
                      value={dto.applications}
                      messages={splitErrors.applications}
                      emailMessages={[] /* we deal with these at the bottom of the create page, not inside the application editor */}
                      qualifications={qualifications}
                      guessedFeeCategory={guessedFeeCategory}
                      selectedApplication={selectedApplication}
                      selectedApplicationInitial={selectedApplication}
                      selectedApplicationIndex={selectedApplicationIndex}
                      setSelectedApplication={setSelectedApplication}
                      onUpdateApplication={handleUpdateApplication}
                      onAddApplication={handleAddApplication}
                      onRemoveApplication={handleRemoveApplication}
                      canRemoveApplications={true}
                    />
                  </Segment>

                  <Divider hidden />

                  <Segment>
                    <ApplicantDetailsEditor
                      messages={detailsErrors.details}
                      value={dto.applicant.details}
                      duplicates={{
                        ucasPersonalId: duplicateUcas,
                        studentId: duplicateStudentId,
                        email: duplicateEmail,
                      }}
                      readOnly={false}
                      onChange={details => {
                        checkForDuplicates(details);
                        setDto({
                          ...dto,
                          applicant: {
                            ...dto.applicant,
                            details,
                          },
                        });
                      }}
                    />
                  </Segment>

                  <Divider hidden />

                  <Segment basic vertical textAlign="right">
                    {permissions.isHotlineUser() && <Button onClick={() => push("/")}>Delete Applicant</Button>}

                    <Dropdown className="button" text="Save and Transfer" disabled={transferOptions.length === 0} options={transferOptions} />

                    <SubmitButton messages={messages} onClick={() => setEditorState({ state: "SAVING" })} disabled={!hasOffer}>
                      {hasOffer ? "Save and Make Offer" : "Save"}
                    </SubmitButton>
                  </Segment>

                  <Segment basic vertical textAlign="right">
                    <ErrorDisplay messages={splitErrors._email_} />
                  </Segment>
                </Grid.Column>
                <Grid.Column width={4}>
                  <ApplicantSidebar
                    dto={dto}
                    permissions={permissions}
                    currentTeam={currentTeam}
                    allTeams={allTeams}
                    user={currentUser}
                    onUpdateLabel={handleUpdateLabel}
                    onAddNote={handleAddNote}
                    onUpdateNote={handleUpdateNote}
                    onDeleteNote={handleDeleteNote}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </RequirementsChecksProvider>
          {editorState.state === "SAVING" && (
            <SaveModal
              dto={dto}
              onCancel={() => setEditorState({ state: "EDITING" })}
              onSaveWithEmail={() => doSave(currentTeam.code, dto, true)}
              onSaveWithoutEmail={() => doSave(currentTeam.code, dto, false)}
            />
          )}
          {editorState.state === "SHOW_SCRIPTS" && (
            <ModalApplicationScript
              applications={editorState.applications.filter(willHaveScript)}
              onOk={() => {
                push(`/applicant/${editorState.id}`);
              }}
            />
          )}
        </Dimmer.Dimmable>
      </Container>
    </Title>
  );
}

import { admissionsTeam, schoolsToTeams } from "@qmspringboard/app/src/model/team";
import { useQuery } from "react-query";
import { School } from "@qmspringboard/app/src/model/types.generated";
import { fetchSchools } from "@qmspringboard/app/src/api";

export default function useAllSchools() {
  const { data } = useQuery<Array<School>>("allSchools", () => fetchSchools().then(schools => schools.items), { cacheTime: 5 * 60 * 1000 });

  return data || [];
}

export function useAllTeams() {
  const schools = useAllSchools();
  return [admissionsTeam, ...schoolsToTeams(schools)];
}

import React from "react";
import { RouteComponentProps } from "react-router";
import { Container, Dimmer, Divider, Icon, Loader, Segment } from "semantic-ui-react";
import SubmitButton from "../components/SubmitButton";
import Title from "../components/Title";
import UserEditor from "../components/UserEditor";
import useUser from "../hooks/useUser";
import usePermissions from "../hooks/usePermissions";
import PermissionsDetails from "../components/PermissionsDetails";

function UserEditorPage({ match }: RouteComponentProps<{ id: string }>) {
  const permissions = usePermissions();
  const [user, status, messages, onChange, onCommit] = useUser(match.params.id);

  const newUser = user == null || user.id < 0;

  if (!permissions) {
    return <Loader>Loading</Loader>;
  }

  return (
    <Title title={newUser ? "New User" : user.username}>
      <Container>
        <Dimmer.Dimmable as={Container}>
          <Dimmer active={status === "loading"} inverted>
            <Loader>Loading...</Loader>
          </Dimmer>

          <UserEditor value={user} messages={messages} readOnly={false} onChange={onChange} permissions={permissions} />

          <Divider hidden />

          <PermissionsDetails user={user} />

          <Divider hidden />

          <Segment basic vertical textAlign="right">
            <SubmitButton onClick={() => onCommit(user)} messages={messages}>
              {user == null || user.id < 0 ? (
                <>
                  <Icon name="mail" /> Add and Email User
                </>
              ) : (
                "Save"
              )}
            </SubmitButton>
          </Segment>
        </Dimmer.Dimmable>
      </Container>
    </Title>
  );
}

export default UserEditorPage;
